import { Button, Card, Form, Input, Layout, Space ,Typography} from 'antd';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import { postData } from '../../utils/fetch';
import './index.less';
const { Title } = Typography;
const { Content } = Layout;
class Login extends React.Component {

    formRef = React.createRef<FormInstance>();

    async login(){
        const username = this.formRef.current!.getFieldValue("username");
        const password = this.formRef.current!.getFieldValue("password");
        const result = await postData("/api/v1/login",{
            username,
            password
        })

        if(result && result.access_token){
            window.location.href = "/#/"
        }

    }

    render() {
        return (
            <Content className='login-wrapper'>
                <Card className='login-card'>
                    <Title level={3}>PIXELHOUSE TOOLS LOGIN</Title>
                    <Form ref={this.formRef} onFinish={this.login.bind(this)}>
                        <Space direction="vertical">
                            <Form.Item label="用户名" name="username" rules={[{ required: true, message: '输入用户名!' }]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item label="密码" name="password" rules={[{ required: true, message: '输入密码' }]}>
                                <Input.Password/>
                            </Form.Item>
                            <div className="btn-box">
                                <Button htmlType="submit" type="primary">LOGIN</Button>
                            </div>
                        </Space>
                    </Form>
                </Card>
            </Content>
        );
    }
}

export default Login;
import {
    Layout,
    Divider,
    Input,
    InputNumber,
    Button,
    Upload,
    Form,
    FormInstance,
    Radio
} from 'antd';
import React from 'react';
import './index.less';

import * as XLSX from "xlsx";
import { ajax } from 'jquery';

const { Content } = Layout;

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

interface BackroomsLevel {
    id: number,
    cardId?: number,
    danger: string,
    levelId: string,
    rareLevel: string,
    desc: string,
    name: string,
    englishName: string
}

const layout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 16 },
};

// const DEFAULT_HP = 100;

function formatId(id: number | string, length: number) {
    let num = "000000000000000000000000000000000000000" + String(id);
    return num.substring(num.length - length);
}


class BackroomsLevelCard extends React.Component {
    formRef = React.createRef<FormInstance>();
    formUploadRef = React.createRef<FormInstance>();
    roleForm = React.createRef<FormInstance>();
    outlookForm = React.createRef<FormInstance>();
    stanceForm = React.createRef<FormInstance>();
    scriptTemplateForm = React.createRef<FormInstance>();

    state: { [key: string]: any } = {
        roles: [],
        generateMen: [],
        xlsxBlobs: [],
        roleData: {},
        outlookData: {},
        stanceData: {},
        scriptTemplateData: {},
        enName: "",
        cnName: ""
    }

    componentDidMount = () => {
        this.loadRoles();
    }

    async loadRoles() {
        const data = await ajax({
            method: "GET",
            url: `/api/v1/backrooms_level_card/roles`
        });

        this.setState({
            roles: data
        });
        return data;
    }

    getRoles(id: string) {
        for (const role of this.state.roles) {
            if (parseInt(role.id, 10) === parseInt(id, 10)) {
                return role
            }
        }
    }

    async requestAndGenerate(id: string | number, count: string) {

        const addresses = await ajax({
            method: "POST",
            url: `/api/v1/backrooms_level_card/address/generate`,
            data: {
                roleId: id,
                count: count
            }
        });


        let data = [
            [
                "图案编号",
                "可变数据：二维码"
            ]
        ];

        data.push.apply(data,
            addresses.map((data: any) => {
                return [
                    `X-Level-ID.${formatId(data.level.cardId + 1000, 4)}`,
                    `https://backrooms.pixelhouse.cn/level_card/${data.hash}`
                ]
            })
        );

        //Create a new Work Sheet using the data stored in an Array of Arrays.
        const workSheet = XLSX.utils.aoa_to_sheet(data);
        // Generate a Work Book containing the above sheet.
        const workBook = {
            Sheets: { data: workSheet, cols: [] },
            SheetNames: ["data"],
        };
        // Exporting the file with the desired name and extension.
        const excelBuffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });
        const fileData = new Blob([excelBuffer], { type: fileType });

        return URL.createObjectURL(fileData);

    }

    async continueRequestAndGenerate(count: string, roles: number[], generateIndex: number = 0): Promise<any> {
        const categoryId = roles[generateIndex];
        const category = this.getRoles(categoryId.toString());
        return this.requestAndGenerate(category.id, count)
            .then(async (blob) => {
                const nextIndex = generateIndex + 1;
                if (roles[nextIndex]) {
                    await this.continueRequestAndGenerate(count, roles, nextIndex);
                }
                this.state.xlsxBlobs.push(blob)
                this.state.generateMen.push(category)
                return blob;
            })
    }

    async onFinish() {
        this.setState({
            generateMen: [],
            xlsxBlobs: [],
        });
        const form = this.formRef.current;
        if (form) {
            const values = form.getFieldsValue();
            if (values.continues) {
                const singleIds: number[] = [];
                const ids = singleIds.concat.apply(
                    singleIds,
                    values.continues.split(",")
                        .map((idRange: string) => {
                            if (idRange.indexOf("-") > -1) {
                                const idStart = parseInt(idRange.split("-")[0], 10);
                                const idEnd = parseInt(idRange.split("-")[1], 10);
                                const ids = [];
                                let i = idStart;
                                for (; i <= idEnd; i++) {
                                    ids.push(i);
                                }
                                return ids;
                            }
                            else {
                                return [parseInt(idRange, 10)];
                            }
                        })
                )
                await this.continueRequestAndGenerate(values.count, ids);
            }
            else {
                this.state.xlsxBlobs.push(await this.requestAndGenerate(values.manCategory.id, values.count));
                this.state.generateMen.push(values.manCategory);
            }
            this.forceUpdate();
        }
    }

    render() {

        return (
            <Content className='backrooms'>
                <Divider orientation="left">生成卡牌</Divider>
                <div className='all-star-category'>
                    <Form {...layout} ref={this.formRef} name="control-hooks" onFinish={this.onFinish.bind(this)}>
                        <Form.Item label="角色" name="manCategory" >
                            <Radio.Group>
                                {
                                    this.state.roles!.map((role: BackroomsLevel) =>
                                        <Radio.Button className='levelButton' key={role.id} value={role}>
                                            <div className="levelBox">
                                                <img src={`https://usecubes.oss-cn-hangzhou.aliyuncs.com/backrooms_level/${role.levelId}.jpg`} alt="" />
                                                <img src={`https://usecubes.oss-cn-hangzhou.aliyuncs.com/backrooms_level/${role.levelId} back.jpg`} alt="" />
                                                <span className='rate'>{role.rareLevel}</span>
                                                <span className='no'>{role.levelId} </span>
                                            </div>
                                        </Radio.Button>
                                    )
                                }
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name="count" label="数量" rules={[{ required: true }]}>
                            <InputNumber />
                        </Form.Item>
                        <Form.Item name="continues" label="连续生成编号" >
                            <Input placeholder='001, 008, 019-035, ...' />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                生成卡牌二维码数据
                            </Button>
                        </Form.Item>
                        {
                            this.state.generateMen && !!this.state.generateMen.length &&
                            this.state.generateMen.map((role: any, index: number) => {
                                const values = this.formRef.current && this.formRef.current.getFieldsValue();
                                const count = ~~(values && values.count);
                                const downloadName = `X-ID.${formatId(role && (role.cardId + 1000), 4)}_${role && role.name}_${count}(PCS).xlsx`;
                                return [<a download={downloadName} key={index} href={this.state.xlsxBlobs[index]}>{downloadName}</a>, <br />]
                            })
                        }
                    </Form>
                </div>
            </Content >
        );
    }
}

export default BackroomsLevelCard;
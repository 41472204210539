import { Layout, Card, Input, Space, Button, Table, Select, Typography } from 'antd';
import React from 'react';
import './index.less';
import { getData, postData } from '../../utils/fetch'
import { JdProductIf } from '../../../../server/src/jd-product/jd-product.interface'
import ReactJsBarcode from './ReactJsBarcode'
import Modal from 'antd/lib/modal/Modal';
import { utils, writeFile } from 'xlsx';

const { Content } = Layout;
const { Option } = Select;
const { Title, Text } = Typography;

interface JdProductWithCount extends JdProductIf {
    count?: number
}

interface LocalPrintBatch {
    id: number
    created: string
    count: number //批次总量
    records: JdProductWithCount[]
}
interface JdProductPrintRecord {
    product: JdProductIf
    count: number
}
interface JDProductPrintBatch {
    created: string
    id: number
    records: JdProductPrintRecord[]
}

class JDProductsPrint extends React.Component {

    componentDidMount = () => {
        this.getList();
    }

    state: { [key: string]: any } = {
        filterProducts: [],
        selectedProducts: [],
        allOriginProducts: [],
        printBatchList: [],
        chooseBatchVisiable: false
    }

    allACProducts: any[] = []

    batchColumns = [
        {
            title: '批次ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '总量',
            dataIndex: 'count',
            key: 'count',
        },
        {
            title: '创建时间',
            dataIndex: 'created',
            key: 'created',
        },
        {
            title: '操作',
            key: 'action',
            render: (batch: LocalPrintBatch) => {
                return <Button onClick={this.chooseHistory.bind(this, batch)}>选择</Button>
            }
        },
    ]

    productColumns = [
        {
            title: '商品名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '商品编码',
            dataIndex: 'usecubesId',
            key: 'usecubesId',
        },
        {
            title: '京东条形码',
            dataIndex: 'jdSkuId',
            key: 'jdSkuId',
        },
        {
            title: '京东EMG编码',
            dataIndex: 'jdEmgId',
            key: 'jdEmgId',
        },
        {
            title: '数量',
            key: 'printCount',
            render: (product: JdProductWithCount) => {
                return <Input onInput={this.onInputCount.bind(this, product)} value={product.count} style={{ width: 200 }} min={1} type="number" />
            }
        },
        {
            title: '操作',
            key: 'action',
            render: (product: JdProductWithCount) => {
                return <Button onClick={this.removeFromTable.bind(this, product)}>移除</Button>
            }
        },
    ]

    clearTable = () => {
        this.setState({
            selectedProducts: []
        })
    }

    makePrint = async () => {
        await postData('/api/v1/jd_product_print', this.state.selectedProducts)
    }

    importFromHistory = async () => {
        const batchList = (await getData('/api/v1/jd_product_print/list')).list;
        const localBatchList: LocalPrintBatch[] = [];
        (batchList as JDProductPrintBatch[]).forEach((remoteBatch: JDProductPrintBatch) => {
            const oneBatch: LocalPrintBatch = {
                id: remoteBatch.id,
                created: remoteBatch.created,
                records: [],
                count: 0,
            }

            remoteBatch.records.forEach((record: JdProductPrintRecord) => {
                oneBatch.records.push({
                    id: record.product.id,
                    name: record.product.name,
                    usecubesId: record.product.usecubesId,
                    jdEmgId: record.product.jdEmgId,
                    jdSkuId: record.product.jdSkuId,
                    count: record.count
                })
                oneBatch.count += record.count;
            })

            localBatchList.push(oneBatch)
        })
        this.setState({
            chooseBatchVisiable: true,
            printBatchList: localBatchList
        })
    }

    chooseHistory = (batch: LocalPrintBatch) => {
        this.setState({
            selectedProducts: batch.records,
            chooseBatchVisiable: false
        })
    }

    downloadJDxls = () => {
        let jdData: any = [];
        this.state.selectedProducts.forEach((p: any) => {
            jdData.push({
                "*ECLP事业部商品编码": p.jdEmgId,
                "商家商品编码": "",
                "商品数量（个）": p.count,
                "代贴条码(是/否)": "",
                "单价": "",
                "质检比例（大件且开通质检服务）0-100": "",
                "是否序列号入库（是/否）": ""
            })
        })
        const wb = utils.book_new();
        utils.book_append_sheet(wb, utils.json_to_sheet(jdData), "采购单导入");
        writeFile(wb, "jd_import_table.xlsx")
    }

    downloadGJPinputXls = () => {
        let jdData: any = [];
        this.state.selectedProducts.forEach((p: any) => {
            const usecubesId = p.usecubesId;
            const ids = usecubesId.split("+");
            ids.forEach((id: string) => {
                if (id[0] === "[") {
                    jdData.push({
                        "商品条码": "",
                        "商品编号": id.substring(3),
                        "商品名称": "",
                        "数量": p.count,
                        "单价": "",
                        "备注(不能超过200个字符)": ""
                    })
                }
            })
        })
        const wb = utils.book_new();
        utils.book_append_sheet(wb, utils.json_to_sheet(jdData), "单据明细");
        writeFile(wb, "jd_gjp_table.xlsx")
    }

    downloadGJPxls = () => {
        let jdData: any = [];
        this.state.selectedProducts.forEach((p: any) => {
            const usecubesId = p.usecubesId;
            const ids = usecubesId.split("+");
            ids.forEach((id: string) => {
                if (id[0] === "[") {
                    switch (id[1]) {
                        case "1":
                            jdData.push({
                                "商品条码": "",
                                "商品名称": "",
                                "商品编号": "B90",
                                "单位": "",
                                "数量": p.count,
                                "单价": "",
                            })
                            break;
                        case "2":
                            jdData.push({
                                "商品条码": "",
                                "商品名称": "",
                                "商品编号": "B125",
                                "单位": "",
                                "数量": p.count,
                                "单价": "",
                            })
                            break;
                        default:
                    }

                    jdData.push({
                        "商品条码": "",
                        "商品名称": "",
                        "商品编号": id.substring(3),
                        "单位": "",
                        "数量": p.count,
                        "单价": "",
                    })

                } else {
                    jdData.push({
                        "商品条码": "",
                        "商品名称": "",
                        "商品编号": id,
                        "单位": "",
                        "数量": p.count,
                        "单价": "",
                    })
                }
            })
        })
        const wb = utils.book_new();
        utils.book_append_sheet(wb, utils.json_to_sheet(jdData), "订单明细");
        writeFile(wb, "jd_gjp_table.xlsx")
    }

    closeModal = () => {
        this.setState({
            chooseBatchVisiable: false
        })
    }

    deleteHistory = (id: number) => {

    }

    removeFromTable = (product: any) => {
        const selectedProducts = this.state.selectedProducts;
        const newSelectedProducts = selectedProducts.filter((p: JdProductWithCount) => p.id !== product.id)
        this.setState({ selectedProducts: newSelectedProducts })
    }

    onSelect = (productId: string, option: any) => {
        const selectedProducts = this.state.selectedProducts;
        const productIdNum = Number(option.key)
        //排除同样的
        if (productId && selectedProducts.every((p: JdProductWithCount) => p.id !== productIdNum)) {
            //获得origin data
            const theOriginProduct = this.state.allOriginProducts.filter((p: JdProductWithCount) => p.id === productIdNum)[0];
            theOriginProduct.count = 1;
            theOriginProduct.key = theOriginProduct.id;
            selectedProducts.push(theOriginProduct)
            this.setState({
                selectedProducts: selectedProducts
            })
        }
    }

    onInputCount = (product: JdProductWithCount, e: any) => {
        const theProduct = this.state.selectedProducts.filter((p: JdProductWithCount) => p.id === product.id)[0];
        theProduct.count = Number(e.target.value);
        //告诉ant的傻逼逻辑 这个数据更新了 该更新界面了
        theProduct.key = Date.now();
        this.forceUpdate();
    }

    getList = async () => {
        let url = '/api/v1/jd_product/list';
        const allOriginProducts = (await getData(url)).list
        this.setState({
            allOriginProducts
        })
    }

    render() {
        const newSelectedProducts = [...this.state.selectedProducts];
        const printBatchList = [...this.state.printBatchList];
        return (
            <Content>
                <Card className="jd-products-content">
                    <Space size="large" direction="vertical" style={{ width: "100%" }}>
                        <Space>
                            <Select optionFilterProp="children" showSearch placeholder="查找商品" style={{ width: 400 }} size="large" onSelect={this.onSelect} filterOption={(value, option) => {
                                if (option && option.value && (option.value as string).indexOf(value) > -1) {
                                    return true;
                                } else {
                                    return false;
                                }
                            }}>

                                {
                                    this.state.allOriginProducts && this.state.allOriginProducts.map((product: JdProductWithCount) => {
                                        return <Option key={product.id} value={product.name}>{product.name}</Option>
                                    })
                                }

                            </Select>
                            <Button type='primary' size='large' onClick={this.clearTable}>清空</Button>
                            <Button type='primary' size='large' onClick={this.makePrint}>制单</Button>
                            <Button type='primary' size='large' onClick={this.importFromHistory}>历史导入</Button>
                            <Button type='primary' size='large' onClick={this.downloadJDxls}>下载京东入库表格</Button>
                            <Button type='primary' size='large' onClick={this.downloadGJPxls}>下载管家婆开单表格</Button>
                            <Button type='primary' size='large' onClick={this.downloadGJPinputXls}>下载管家婆入库表格</Button>
                        </Space>
                        <Table key="key" rowKey={(record)=>record.key} dataSource={newSelectedProducts} columns={this.productColumns} pagination={false} />
                    </Space>
                    <Modal width={800} title="选择批次导入" footer={[]} closable={true} maskClosable={true} open={this.state.chooseBatchVisiable} destroyOnClose onCancel={this.closeModal}>
                        <Table rowKey="id" dataSource={printBatchList} columns={this.batchColumns} pagination={false} />
                    </Modal>
                </Card>
                <Card className="jd-products-print">
                    <Content className="print square">
                        {
                            this.state.selectedProducts.map((product: JdProductWithCount) => {
                                return Array(product.count!).fill(0).map((v, i) => {
                                    return <div className="single">
                                        <Space key={product.id + '-' + i} direction="vertical" className="space" align="center" size="small">
                                            <Title level={4} className="title">{product.name}</Title>
                                            <Text>商品型号：{product.usecubesId}</Text>
                                            <Text>尺寸：100mm x 100mm x 100mm</Text>
                                            <Text className="address">地址：浙江省义乌市四季路1000号1号综合楼702</Text>
                                            <ReactJsBarcode width={1.6} height={50} value={'POP' + product.jdSkuId} />
                                        </Space>
                                    </div>
                                })
                            })
                        }
                    </Content>
                </Card>
            </Content >
        );
    }
}

export default JDProductsPrint;
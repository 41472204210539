import {
    LoadingOutlined,
    UploadOutlined 
} from '@ant-design/icons'

import {
    Layout,
    Divider,
    Input,
    InputNumber,
    Button,
    Upload,
    Form,
    Image,
    FormInstance,
    Radio,
    message,
    Select
} from 'antd';
import React from 'react';
import './index.less';

import * as XLSX from "xlsx";
import { ajax } from 'jquery';

import { Random } from "random-js";
import axios from 'axios';
import { PassThrough } from 'stream';

const random = new Random();
const { Content } = Layout;

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

interface SkibidiToiletCore {
    id: number,
    rate: string,
    no: number,
    name: string,
    cnName: string,
    description : string,
    max_equipment_number : number,
    image : any
}


interface SkibidiToiletEquipment {
    id: number,
    rate: string,
    no: number,
    name: string,
    cnName: string,
    description : string,
    suitablecores : [SkibidiToiletCore],
    image: any
}


interface equipmentToHero  {
    id : number,
    equipmentCount : number,
    equipmentCategory : SkibidiToiletEquipment
}


interface SkibidiToiletHero {
    id: number,
    rate: string,
    no: number,
    name: string,
    cnName: string,
    description : string,
    corecategorys : [SkibidiToiletCore],
    equipmentToHeros : [equipmentToHero],
    image: any
}


const layout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 16 },
};

// const DEFAULT_HP = 100;

function formatId(id: number | string, length: number) {
    let num = "000000000000000000000000000000000000000" + String(id);
    return num.substring(num.length - length);
}

// 随机int
function randomInt(under:number){
    return random.integer(0, under - 1)
}



function getRandomItem(list:any[], count:number, excludes?:any[], allowRepeat?:boolean){
    if(excludes){
        for(const exclude of excludes){
            const foundIndex = list.indexOf(exclude);
            if(foundIndex> -1){
                list.splice(foundIndex, 1)
            }
        }

    }

    if( count <= list.length || allowRepeat){
        const indexes = [];
        for(var i=0; i<count; ){
            let randomIndex = randomInt(list.length);
            if(indexes.indexOf(randomIndex) === -1 || allowRepeat){
                indexes.push(randomIndex);
                i++;
            }
        }
        return indexes.map((index) => list[index])
    }
    else{
        return [];
    }
}


class SkibidiToiletCard extends React.Component {

    formRef = React.createRef<FormInstance>();

    state: { [key: string]: any } = {
        cores: [],
        equipments: [],
        heros: []
    }

    componentDidMount = () => {
        this.loadCores();
        this.loadEquipments();
        this.loadHeros();

        this.loadTeams();
        this.loadScripts();
    }

    // 载入核心卡
    async loadCores() {
        let core_options: { value: any; label: JSX.Element; }[] = [];

        const data = await ajax({
            method: "GET",
            url: `/api/v1/skibidi_toilet_card/cores`
        });

        data.map( (_core: { id: any , cnName : string }) => {
            const Label = <span>{_core.cnName}</span>;
            core_options.push( {
                value : _core.id,
                label : Label
            })
        } )

       
        this.setState({
            cores: data,
            core_options
        });
        return data;
    }

    // 载入装备卡
    async loadEquipments() {

        let equipment_options: { value: any; label: JSX.Element; }[] = [];

        const data = await ajax({
            method: "GET",
            url: `/api/v1/skibidi_toilet_card/equipments`
        });


        data.map( (_equipment: { id: any , cnName : string }) => {
            const Label = <span>{_equipment.cnName}</span>;
            equipment_options.push( {
                value : _equipment.id,
                label : Label
            })
        } )

        this.setState({
            equipments: data,
            equipment_options
        });
        return data;
    }


    // 载入英雄卡
    async loadHeros() {
        const data = await ajax({
            method: "GET",
            url: `/api/v1/skibidi_toilet_card/heros`
        });

        this.setState({
            heros: data
        });
        return data;
    }

    //新增一个核心实例
    async addCore(categoryID:number){
        const count = prompt('需要生成核心的数量');        
        const resData = await ajax({
            method : "POST",
            url : `/api/v1/skibidi_toilet_card/core/add`,
            data : {
                categoryID,
                count
            }
        });


    }


    //新增一个equipment实例
    async addEquipment(categoryID: number){
        const count = prompt('需要生成装备的数量');
        const resData = await ajax({
            method : "POST",
            url : `/api/v1/skibidi_toilet_card/equipment/add`,
            data : {
                categoryID,
                count
            }
        });
    }

    //新增一个英雄实例
    async addHero(categoryID:number){
        const count = prompt('需要生成英雄的数量');
        const resData = await ajax({
            method : "POST",
            url : `/api/v1/skibidi_toilet_card/hero/add`,
            data : {
                categoryID,
                count
            }
        });
    }

    async createFight(){

        const scriptID = Number(this.state.currentScriptID);
        if(!scriptID){return;}

        const fightData = await ajax({
            method : "POST",
            url : `/api/v1/skibidi_toilet_card/fight`,
            data : {
                loginUserId : 1,
                scriptID
            }
        });

        this.setState({
            fightData
        })
    }

    async startAutoFight():Promise<any>{

        const fightId = this.state.fightData ? this.state.fightData.id : null;

        if(!fightId){return}

        let changeTurn : boolean = false;

        if(!this.state.fightData.log.state.point ){
            changeTurn = true;
        }

        const res = await axios.post(
            `/api/v1/skibidi_toilet_card/fight/update`,
            {
                id : fightId,
                pushData : {
                    autoFight : true,
                    changeTurn }
            }
        )

        this.updateFightViewByResponse(res);

        if(res.data.result === "challenging"){
            return this.startAutoFight();
        } 
        else{
            return res.data;
        }

    }

    async changeFightTurn(){
        const fightId = this.state.fightData ? this.state.fightData.id : null;
        const res = await axios.post(
            `/api/v1/skibidi_toilet_card/fight/update`,
            {
                id : fightId,
                pushData : {
                    changeTurn :true 
                }
            }
        )
    
        this.updateFightViewByResponse(res)
    }

    async playSkill(buffSkill:any){
        const fightId = this.state.fightData ? this.state.fightData.id : null;
        
        const res = await axios.post(
            `/api/v1/skibidi_toilet_card/fight/update`,
            {
                id : fightId,
                pushData : {
                    playingUid :  buffSkill.uid
                }
            }
        )

        this.updateFightViewByResponse(res)
    }


    async updateFightViewByResponse(res:any){
        if(res.data.result !== "challenging"){
            message.success(res.data.result);
        }
        if(res.data.log.state.playing ){
            if(res.data.log.state.playing.dodge){
                message.success("丢失");
            }
            if(res.data.log.state.playing.critical){
                message.success("暴击");
            }
        }


        this.setState({
            fightData: res.data
        })
    }


    async loadScripts(){
        axios.get(
            `/api/v1/skibidi_toilet_card/scripts`
        ).then( res => {

            let script_options: { value: any; label: JSX.Element; }[] = [];
            res.data.map( (s: { id: any; }) => {
                const Label = <span>{s.id}</span>;
                script_options.push( {
                    value : s.id,
                    label : Label
                })
            } )

            this.setState({
                script_options 
            })
        } )
    }

    async changeScript(currentScriptID : string){
        this.setState({currentScriptID});
    }


    async changeCore(currentCoreID : number){
        this.setState({currentCoreID})
    }

    
    async generateAHero(){
        const currentCoreID = this.state.currentCoreID;
        if(!currentCoreID){
            message.error('请选择一个核心');
            return;
        }

        const equipmenCategoryIds = [];

        if(this.state.equipmentIDA){
            equipmenCategoryIds.push( Number(this.state.equipmentIDA) );
        }
        if(this.state.equipmentIDB){
            equipmenCategoryIds.push(Number(this.state.equipmentIDB));
        }
        if(this.state.equipmentIDC){
            equipmenCategoryIds.push(Number(this.state.equipmentIDC));
        }
        if(this.state.equipmentIDD){
            equipmenCategoryIds.push(Number(this.state.equipmentIDD));
        }
        if(this.state.equipmentIDE){
            equipmenCategoryIds.push(Number(this.state.equipmentIDE));
        }
        if(this.state.equipmentIDF){
            equipmenCategoryIds.push(Number(this.state.equipmentIDF));
        }
        if(this.state.equipmentIDG){
            equipmenCategoryIds.push( Number(this.state.equipmentIDG));
        }
        if(this.state.equipmentIDH){
            equipmenCategoryIds.push(Number(this.state.equipmentIDH));
        }
        if(this.state.equipmentIDI){
            equipmenCategoryIds.push(Number(this.state.equipmentIDI));
        }
        if(this.state.equipmentIDJ){
            equipmenCategoryIds.push(Number(this.state.equipmentIDJ));
        }
        if(this.state.equipmentIDK){
            equipmenCategoryIds.push(Number(this.state.equipmentIDK));
        }
        if(this.state.equipmentIDL){
            equipmenCategoryIds.push(Number(this.state.equipmentIDL));
        }

        this.setState({
            compoundImagePrompt:"......",
            compoundImageUrl:null
        })

        const resData = await ajax({
            method : "POST",
            url : `/api/v1/skibidi_toilet_card/hero/compoundusecategorys`,
            data : {
                coreCategoryId : Number(currentCoreID),
                equipmenCategoryIds
            }
        });

        
        this.setState({
            compoundImagePrompt:resData.originPrompt,
            compoundImageUrl: `https://oss.usecubes.cn/${resData.image.url}`
        })
    }

    async changeEquipmentA(equipmentIDA : number){
        this.setState({equipmentIDA})
    }
    async changeEquipmentB(equipmentIDB : number){
        this.setState({equipmentIDB})
    }
    async changeEquipmentC(equipmentIDC : number){
        this.setState({equipmentIDC})
    }
    async changeEquipmentD(equipmentIDD : number){
        this.setState({equipmentIDD})
    }
    async changeEquipmentE(equipmentIDE : number){
        this.setState({equipmentIDE})
    }
    async changeEquipmentF(equipmentIDF : number){
        this.setState({equipmentIDF})
    }
    async changeEquipmentG(equipmentIDG : number){
        this.setState({equipmentIDG})
    }
    async changeEquipmentH(equipmentIDH : number){
        this.setState({equipmentIDH})
    }
    async changeEquipmentI(equipmentIDI : number){
        this.setState({equipmentIDI})
    }
    async changeEquipmentJ(equipmentIDJ : number){
        this.setState({equipmentIDJ})
    }
    async changeEquipmentK(equipmentIDK : number){
        this.setState({equipmentIDK})
    }
    async changeEquipmentL(equipmentIDL : number){
        this.setState({equipmentIDL})
    }


    async generateQRCodes(){
        this.setState({
            generateCards:[],  //这里的包含 cores ,equipments , heros
            xlsxBlobs:[],
        });
        const form = this.formRef.current;
        
        if(form){
            const values = form.getFieldsValue();
            const type = values.type || 'core';

            if(values.continues){
                const singleIds:number[] = [];

                const ids = singleIds.concat.apply(
                    singleIds,
                    values.continues.split(",")
                        .map((idRange:string) => {
                            if(idRange.indexOf("-") > -1){
                                const idStart = parseInt(idRange.split("-")[0], 10);
                                const idEnd = parseInt(idRange.split("-")[1], 10);
                                const ids = [];
                                let i = idStart;
                                for(; i<=idEnd; i++){
                                    ids.push(i);
                                }
                                return ids;
                            }
                            else{
                                return [parseInt(idRange, 10)];
                            }
                        })
                )
                await this.continueRequestAndGenerate(values.count, ids,type);
            }
            else{
                this.state.xlsxBlobs.push(await this.requestAndGenerate(values.manCategory.id, values.count,type));
                this.state.generateCards.push(values.manCategory);
            }
            this.forceUpdate();
        }
    }


    async continueRequestAndGenerate(count:string, categories:number[], type: string , generateIndex:number = 0):Promise<any>{
        const categoryId = categories[generateIndex];
        const category = this.getCategory(categoryId.toString(),type);
        return this.requestAndGenerate(category.id, count, type)
            .then(async (blob) => {
                const nextIndex = generateIndex + 1;
                if(categories[nextIndex]){
                    await this.continueRequestAndGenerate(count, categories, type,nextIndex);
                }
                this.state.xlsxBlobs.push(blob)
                this.state.generateCards.push(category)
                return blob;
            })
    }

    async requestAndGenerate(id:string|number, count:string, type: string){

        const addresses = await ajax({
            method: "POST",
            url: `/api/v1/skibidi_toilet_card/${type}/add`,
            data: {
                categoryID:id,
                count:count,
                type
            }
        });

        const values = this.formRef.current && this.formRef.current.getFieldsValue();

        let data = [
            [
                "图案编号", 
                "可变数据：ID", 
                "可变数据：二维码"
            ]
        ];

        let idIndex = parseInt(values.startId, 10);

        let prefix : any = {
            "hero" : "H",
            "equipment" : "E",
            "core" : "C"
        }
        
        data.push.apply(data,
            addresses.addresses.map((data: any) => {
                return [
                    `${prefix[type]}${data[type].category.no}`, 
                    `No.${formatId(idIndex++, 4)}`, 
                    `https://toilet.usecubes.cn/as/${data.hash}`
                ]
            })
        );

          //Create a new Work Sheet using the data stored in an Array of Arrays.
        const workSheet = XLSX.utils.aoa_to_sheet(data);
        // Generate a Work Book containing the above sheet.
        const workBook = {
            Sheets: { data: workSheet, cols: [] },
            SheetNames: ["data"],
        };
        // Exporting the file with the desired name and extension.
        const excelBuffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });
        const fileData = new Blob([excelBuffer], { type: fileType });
        return URL.createObjectURL(fileData);
    }



    getCategory(id:string,type:string){
        let selectCategory;

        switch(type){
            case "core":
                selectCategory = this.state.cores;
            break;
            case "equipment":
                selectCategory = this.state.equipments;
            break;
            case "hero":
                selectCategory = this.state.heros;
            break;
        }

        for(const category of selectCategory){
            if(parseInt(category.no, 10) === parseInt(id, 10)){
                return category
            }
        }
    }
    loadTeams(){
        const skibidi_test_teams = localStorage.getItem("skibidi_test_teams");
        const teams = skibidi_test_teams?.split(";");
        this.setState({
            testGroupTeams:teams?.map(team => {
                return {
                    members:team.split(",")
                }
            })
        })
    }

    changeStartTestTeamId(value: any){
        this.setState({
            startTestTeamId:value
        })
    }
    changeEndTestTeamId(value: any){
        this.setState({
            endTestTeamId:value
        })
    }

    changeTestTeamCount(value: any){
        this.setState({
            testTeamCount:value
        })
    }

    async startGroupTest(){
        return new Promise((resolve, reject) => {
            this.setState({
                testTeamIndex:0
            }, () => {
                resolve(this.testTeam())
            })
        })
    }

    async testTeam(){
        const rep = await ajax({
            method: "GET",
            url: `/api/v1/skibidi_toilet_card/fight/test`,
            data: {
                challengeId:this.state.groupTestScriptID,
                team:this.state.testGroupTeams[this.state.testTeamIndex].members
            }
        });
        this.state.testGroupTeams[this.state.testTeamIndex].response = rep;
        this.state.testTeamIndex++;
        return new Promise((resolve, reject) => {
            this.forceUpdate(() => {
                if(this.state.testGroupTeams[this.state.testTeamIndex]){
                    resolve(this.testTeam())
                }
                else{
                    resolve(this.state.testGroupTeams);
                }
            })
        })

    }


    createTeams(){
        const teams = [];
        const dirId= this.state.endTestTeamId - this.state.startTestTeamId;
        for(let n=0; n<this.state.testTeamCount; n++){
            teams.push({
                members:[
                    randomInt(dirId) + this.state.startTestTeamId, 
                    randomInt(dirId) + this.state.startTestTeamId, 
                    randomInt(dirId) + this.state.startTestTeamId
                ]
            })
        }
        this.setState({
            testGroupTeams:teams
        })
        localStorage.setItem("skibidi_test_teams", teams.map(team => team.members.join(",")).join(";"))
    }


    async changeGroupTestScript(groupTestScriptID : string){
        this.setState({groupTestScriptID});
    }

    async changeTestScriptsValue(e:any){
        this.setState({
            testScriptsValue:e.target.value
        })
    }
    async startMultiGroupTest(){
        
        const singleIds:number[] = [];
        const ids = singleIds.concat.apply(
            singleIds,
            this.state.testScriptsValue.split(",")
                .map((idRange:string) => {
                    if(idRange.indexOf("-") > -1){
                        const idStart = parseInt(idRange.split("-")[0], 10);
                        const idEnd = parseInt(idRange.split("-")[1], 10);
                        const ids = [];
                        let i = idStart;
                        for(; i<=idEnd; i++){
                            ids.push(i);
                        }
                        return ids;
                    }
                    else{
                        return [parseInt(idRange, 10)];
                    }
                })
            )
        this.setState({
            multiGroupTestResult:[]
        }, () => {
            this.startMultiGroupTestForEach(ids, 0);
        })
    }

    async startMultiGroupTestForEach(ids:number[], index:number){
        return new Promise((resolve, reject) => {

            const id = ids[index];
            if(id){

                this.setState({
                    groupTestScriptID:id
                }, async ()=>{
                    const result = await this.startGroupTest();
                    this.state.multiGroupTestResult.push(this.dumpTestResult());
                    this.forceUpdate();
                    index++;
                    resolve(this.startMultiGroupTestForEach(ids, index));
                })
            }
            else{
                resolve(null);
            }
        })
    }



    dumpTestResult(){
        const groupCount  = this.state.testGroupTeams && this.state.testGroupTeams.length;
        if(groupCount){
            let winCount = 0;
            let loseCount = 0;
            let standoffCount = 0;
            this.state.testGroupTeams.forEach((group:any) => {
                if(group.response){
                    switch(group.response.result){
                        case "win":
                            winCount++;
                            break;
                        case "lose":
                            loseCount++;
                            break;
                        case "standoff":
                            standoffCount++;
                            break;
                    }
                }
            });
            return {
                scriptId:this.state.groupTestScriptID,
                win : winCount/groupCount,
                lose : loseCount/groupCount,
                standoff : standoffCount/groupCount
            }
        }
    }



    render() {

        let testResult:any = this.dumpTestResult();
        
        

        const heroElement = (hero:any) => {
            return <div className="heroCard" key={hero.id}>
                <div>{hero.category && hero.category.cnName}</div>
                <div>[{hero.id}]</div>
                <div className='hpValue'>{hero.data.hp}</div>
                <div className='defenseValue'>{hero.data.defense}</div>
                <div>attack:{hero.attack}</div>
                <div>defense:{hero.defense}</div>
                <div>speed:{hero.speed}</div>
                <div>hitrate:{hero.hitrate}</div>
                <div>critrate:{hero.critrate}</div>
                <div> 
                    {hero.data.buffs.map((buff:any) => {
                            return <div key={buff.uid} className='buffContainer' onClick={()=>{alert(buff.category.buffDescription)}}>
                                <span className="buff" style={{transform:`rotateY(${buff.executeCount * 360}deg)`}}>
                                    <span className='buffName'>{buff.skill.category.cnName}</span>
                                    <span className='buffState'>{buff.state}</span>
                                </span>
                            </div>

                        })
                    }
                </div>
            </div>
        }

        const skillElement = (buffSkill:any) => {
            return <button key={buffSkill.uid} onClick={this.playSkill.bind(this, buffSkill)} className="buffSkill">
                <span>{buffSkill.skill.hero.category && buffSkill.skill.hero.category.cnName} </span>
                <span>[{buffSkill.skill.hero.id}]</span>
                <span>{buffSkill.skill.category.cnName}{buffSkill.coreSkill ? "(核心技)":""}</span>
                <p className='description'>{buffSkill.skill.category.description}</p>
                {buffSkill.isAttack && <span className='attackValue'>{buffSkill.attack}</span>}
                {buffSkill.isDefense && <span className='defenseValue'>{buffSkill.defense}</span>}
            </button>
        }



        return (
            <div className='skibidiToiletContent'>
                选择事件ID：<Select  style={{ width: 120 }} options={this.state.script_options} onChange={this.changeScript.bind(this)}></Select>
                &nbsp;
                {this.state.currentScriptID ? <button onClick={this.createFight.bind(this)}> 创建战斗！</button> : null}
                &nbsp;
                {this.state.fightData ?
                     <span>
                        <button onClick={this.startAutoFight.bind(this)}> 自动战斗</button>
                        <button onClick={this.changeFightTurn.bind(this)}> 结束回合</button>
                     </span>
                     :
                     null
                }

                {this.state.fightData  ? 
                    <div>
                        <div className={`teamView ${this.state.fightData.log.state.turn === "challenge" ? "active": ""}`}>
                            {this.state.fightData.log.state.challenge.team.map(heroElement)}
                        </div>
                        <div className={`teamView ${this.state.fightData.log.state.turn === "beChallenged" ? "active": ""}`}>
                            {this.state.fightData.log.state.beChallenged.team.map(heroElement)}
                        </div>
                        <Divider orientation="left"></Divider>
                        <div>
                            <p className='steps'>总行动数：{this.state.fightData.log.step}</p>
                            <p className='points'>剩余点数：{this.state.fightData.log.state.point}</p>
                            <div>
                            {this.state.fightData.log.state.buffSkills.map(skillElement)}
                            </div>
                        </div>
                    </div>
                    : null
                }
                <Divider orientation="left">批量自动对战测试</Divider>
                选择事件ID：
                <Select  style={{ width: 120 }} options={this.state.script_options} onChange={this.changeGroupTestScript.bind(this)}></Select>
                <Button onClick={this.startGroupTest.bind(this)}>单个测试</Button>
                {testResult && <span className='fightRate'>
                        <span className='rateHolder'>
                            <span className='rateValue'>胜：{`${~~(100*testResult.win)}%`}</span>
                            <span className='rateBar winRate' style={{minWidth:`${~~(200*testResult.win)}px`}}></span>
                        </span>
                        <span className='rateHolder'>
                            <span className='rateValue'>平：{`${~~(100*testResult.standoff)}%`}</span>
                            <span className='rateBar standoffRate' style={{minWidth:`${~~(200*testResult.standoff)}px`}}></span>
                        </span>
                        <span className='rateHolder'>
                            <span className='rateValue'>败：{`${~~(100*testResult.lose)}%`}</span>
                            <span className='rateBar loseRate' style={{minWidth:`${~~(200*testResult.lose)}px`}}></span>
                        </span>
                </span>}
                <br/>
                连续事件测试：<Input placeholder='1, 3, 1-10, ...' value={this.state.testScriptsValue} onChange={this.changeTestScriptsValue.bind(this)}/>
                <Button onClick={this.startMultiGroupTest.bind(this)}>连续测试</Button>
                {this.state.multiGroupTestResult && 
                !!this.state.multiGroupTestResult.length && 
                this.state.multiGroupTestResult.map((testResult:any) => {
                    return <p className='multiGroupTestResult'>
                        <span className='scriptId'>事件：{testResult.scriptId}</span>
                    <span className='fightRate'>
                        <span className='rateHolder'>
                            <span className='rateValue'>胜：{`${~~(100*testResult.win)}%`}</span>
                            <span className='rateBar winRate' style={{minWidth:`${~~(200*testResult.win)}px`}}></span>
                        </span>
                        <span className='rateHolder'>
                            <span className='rateValue'>平：{`${~~(100*testResult.standoff)}%`}</span>
                            <span className='rateBar standoffRate' style={{minWidth:`${~~(200*testResult.standoff)}px`}}></span>
                        </span>
                        <span className='rateHolder'>
                            <span className='rateValue'>败：{`${~~(100*testResult.lose)}%`}</span>
                            <span className='rateBar loseRate' style={{minWidth:`${~~(200*testResult.lose)}px`}}></span>
                        </span>
                    </span>
                    </p>
                })
                }
                <br/>
                <div className='teams'>
                    {
                        this.state.testGroupTeams && this.state.testGroupTeams.map((team:any, index:any) => {
                            return <div className={`team ${this.state.testGroupTeams[index]?.response?.result} ${this.state.testTeamIndex === index ? "testing" : ""}`} 
                                key={index}>
                                    <div className='heroIdBox'>
                                        {team.members.map((id:any, index:any) => {
                                            return <div className='heroId' key={index}>{id}</div>
                                        })}
                                    </div>
                                    {   team.response && 
                                        <div className='heroBox'>
                                            {   team.response.log.state.challenge.team.map((hero:any, index:any) => {
                                                return <div className='hero' key={index}>
                                                    <div>{hero.category.cnName} hp:{hero.data.hp}/{hero.data.maxHp}</div>
                                                </div>
                                            })}
                                            <div>--------------</div>
                                            {   team.response.log.state.beChallenged.team.map((hero:any, index:any) => {
                                                return <div className='hero' key={index}>
                                                    <div>{hero.category.cnName} hp:{hero.data.hp}/{hero.data.maxHp}</div>
                                                </div>
                                            })}
                                        </div>
                                    }
                                    {
                                        team.response &&
                                        <div className='fightResult'>
                                            <span>{team.response.log.step} steps</span>
                                            <span> {team.response.result}</span>
                                        </div>
                                    }

                            </div>
                        })
                    }
                </div>
                <br/>
                英雄：<InputNumber  style={{ width: 100 }} placeholder='起始hero_id' value={this.state.startTestTeamId} onChange={this.changeStartTestTeamId.bind(this)}/>
                到<InputNumber  style={{ width: 100 }}  placeholder='结束hero_id' value={this.state.endTestTeamId} onChange={this.changeEndTestTeamId.bind(this)}/>
                随机创建<InputNumber placeholder='战队数量' value={this.state.testTeamCount} onChange={this.changeTestTeamCount.bind(this)}/>
                个战队
                <br/><Button  onClick={this.createTeams.bind(this)} >开始创建</Button>
                
                


                <Divider orientation="left">上传脚本模板</Divider>
                <div className='all-star-category'>
                    
                    <Upload {...{
                        name: 'template',
                        method:'PUT',
                        action: '/api/v1/skibidi_toilet_card/next/script',
                        onChange(info) {
                            if (info.file.status !== 'uploading') {
                                console.log(info.file, info.fileList);
                            }
                            if (info.file.status === 'done') {
                                message.success(`${info.file.name} file uploaded successfully`);
                            } else if (info.file.status === 'error') {
                                message.error(`${info.file.name} file upload failed.`);
                            }
                        },
                    }}>
                        <Button icon={<UploadOutlined />}>上传，[脚本模板id].json</Button>
                    </Upload>
                </div>


                <Divider orientation="left">合成英雄</Divider>

                选择核心：<Select  style={{ width: 120 }} options={this.state.core_options} onChange={this.changeCore.bind(this)}></Select>
                {this.state.currentCoreID ? <img src={`https://oss.usecubes.cn/${this.state.cores.find( (e:any) => e.id === this.state.currentCoreID).image.url}?x-oss-process=image/resize,w_100,limit_0`}></img> : null}
                <br />
                选择装备1: <Select  style={{ width: 120 }} options={this.state.equipment_options} onChange={this.changeEquipmentA.bind(this)}></Select>
                {this.state.equipmentIDA ? <img src={`https://oss.usecubes.cn/${this.state.equipments.find( (e:any) => e.id === this.state.equipmentIDA).image.url}?x-oss-process=image/resize,w_100,limit_0`}></img> : null}
                <br />
                选择装备2: <Select  style={{ width: 120 }} options={this.state.equipment_options} onChange={this.changeEquipmentB.bind(this)}></Select>
                {this.state.equipmentIDB ? <img src={`https://oss.usecubes.cn/${this.state.equipments.find( (e:any) => e.id === this.state.equipmentIDB).image.url}?x-oss-process=image/resize,w_100,limit_0`}></img> : null}
                <br />
                选择装备3: <Select  style={{ width: 120 }} options={this.state.equipment_options} onChange={this.changeEquipmentC.bind(this)}></Select>
                {this.state.equipmentIDC ? <img src={`https://oss.usecubes.cn/${this.state.equipments.find( (e:any) => e.id === this.state.equipmentIDC).image.url}?x-oss-process=image/resize,w_100,limit_0`}></img> : null}
                <br />
                选择装备4: <Select  style={{ width: 120 }} options={this.state.equipment_options} onChange={this.changeEquipmentD.bind(this)}></Select>
                {this.state.equipmentIDD ? <img src={`https://oss.usecubes.cn/${this.state.equipments.find( (e:any) => e.id === this.state.equipmentIDD).image.url}?x-oss-process=image/resize,w_100,limit_0`}></img> : null}
                <br />
                选择装备5: <Select  style={{ width: 120 }} options={this.state.equipment_options} onChange={this.changeEquipmentE.bind(this)}></Select>
                {this.state.equipmentIDE ? <img src={`https://oss.usecubes.cn/${this.state.equipments.find( (e:any) => e.id === this.state.equipmentIDE).image.url}?x-oss-process=image/resize,w_100,limit_0`}></img> : null}
                <br />
                选择装备6: <Select  style={{ width: 120 }} options={this.state.equipment_options} onChange={this.changeEquipmentF.bind(this)}></Select>
                {this.state.equipmentIDF ? <img src={`https://oss.usecubes.cn/${this.state.equipments.find( (e:any) => e.id === this.state.equipmentIDF).image.url}?x-oss-process=image/resize,w_100,limit_0`}></img> : null}
                <br />
                选择装备7: <Select  style={{ width: 120 }} options={this.state.equipment_options} onChange={this.changeEquipmentG.bind(this)}></Select>
                {this.state.equipmentIDG ? <img src={`https://oss.usecubes.cn/${this.state.equipments.find( (e:any) => e.id === this.state.equipmentIDG).image.url}?x-oss-process=image/resize,w_100,limit_0`}></img> : null}
                <br />
                选择装备8: <Select  style={{ width: 120 }} options={this.state.equipment_options} onChange={this.changeEquipmentH.bind(this)}></Select>
                {this.state.equipmentIDH ? <img src={`https://oss.usecubes.cn/${this.state.equipments.find( (e:any) => e.id === this.state.equipmentIDH).image.url}?x-oss-process=image/resize,w_100,limit_0`}></img> : null}
                <br />
                选择装备9: <Select  style={{ width: 120 }} options={this.state.equipment_options} onChange={this.changeEquipmentI.bind(this)}></Select>
                {this.state.equipmentIDI ? <img src={`https://oss.usecubes.cn/${this.state.equipments.find( (e:any) => e.id === this.state.equipmentIDI).image.url}?x-oss-process=image/resize,w_100,limit_0`}></img> : null}
                <br />
                选择装备10: <Select  style={{ width: 120 }} options={this.state.equipment_options} onChange={this.changeEquipmentJ.bind(this)}></Select>
                {this.state.equipmentIDJ ? <img src={`https://oss.usecubes.cn/${this.state.equipments.find( (e:any) => e.id === this.state.equipmentIDJ).image.url}?x-oss-process=image/resize,w_100,limit_0`}></img> : null}
                <br />
                选择装备11: <Select  style={{ width: 120 }} options={this.state.equipment_options} onChange={this.changeEquipmentK.bind(this)}></Select>
                {this.state.equipmentIDK ? <img src={`https://oss.usecubes.cn/${this.state.equipments.find( (e:any) => e.id === this.state.equipmentIDK).image.url}?x-oss-process=image/resize,w_100,limit_0`}></img> : null}
                <br />
                选择装备12: <Select  style={{ width: 120 }} options={this.state.equipment_options} onChange={this.changeEquipmentL.bind(this)}></Select>
                {this.state.equipmentIDL ? <img src={`https://oss.usecubes.cn/${this.state.equipments.find( (e:any) => e.id === this.state.equipmentIDL).image.url}?x-oss-process=image/resize,w_100,limit_0`}></img> : null}
                <br />
                <button onClick={this.generateAHero.bind(this)}> 合成英雄 </button>
                <br />
                {!!this.state.compoundImagePrompt && 
                    <span>{this.state.compoundImagePrompt}</span>
                }
                <br />
                {!!this.state.compoundImageUrl && 
                    <img className='compoundImage' src={this.state.compoundImageUrl}></img>
                }


                <Divider orientation="left">生成卡牌</Divider>
                <Form {...layout} ref={this.formRef} name="control-hooks" onFinish={this.generateQRCodes.bind(this)}>
                    
                        <Form.Item name="type">
                            <Select
                                defaultValue="core"
                                style={{ width: 120 }}
                                options={[{ value: 'core', label: '核心卡' },{ value: 'equipment', label: '装备卡' },{ value: 'hero', label: '英雄卡' }]}
                            />
                        </Form.Item>

                        <Form.Item name="count" label="数量" rules={[{ required: true }]}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item name="startId" label="起始ID" rules={[{ required: true }]} >
                            <InputNumber placeholder='0000'/>
                        </Form.Item>
                        <Form.Item name="continues" label="连续生成编号" >
                            <Input placeholder='001, 008, 019-035, ...'/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                            生成卡牌二维码数据
                            </Button>          
                    </Form.Item>  
                            
                    {
                        this.state.generateCards && !!this.state.generateCards.length && 
                        this.state.generateCards.map((card: any, index:number) => {
                            const values = this.formRef.current && this.formRef.current.getFieldsValue();
                            const startId = ~~(values && values.startId);
                            const count = ~~(values && values.count);
                            const endId = startId + count - 1;

                            const prefix : any = {
                                "hero" : "H",
                                "equipment" : "E",
                                "core" : "C"
                            }

                            const downloadName = `${prefix[(values.type || 'core')]}${(card && card.no)}_${card && card.name}_ID:${formatId(startId, 4)}-ID:${formatId(endId, 4)}_${count}(PCS).xlsx`;
                            return [<a download={downloadName} key={index} href={this.state.xlsxBlobs[index]}>{downloadName}</a>,<br/>]
                        })
                    }
                </Form> 


                <Divider orientation="left">核心卡牌Category</Divider>
                    <div className="cardContent">
                        {
                            this.state.cores!.map((core:SkibidiToiletCore) =>
                                <div className='card' key={core.id} onClick={this.addCore.bind(this,core.id)} >
                                    <span className='rate'>{core.rate} </span>
                                    <span className='name'>{core.cnName}</span>
                                    <p>{core.description}</p>
                                    <p>最大装备数量:{core.max_equipment_number}</p>
                                    <img src={`https://oss.usecubes.cn/${core.image.url}`} />
                                </div>
                            )
                        }
                    </div> 

                <Divider orientation="left">装备卡牌Category</Divider>

                <div className="cardContent">
                    {
                        this.state.equipments!.map((equipment:SkibidiToiletEquipment) =>
                            <div className='card' key={equipment.id} onClick={this.addEquipment.bind(this,equipment.id)}>
                                <span className='rate'>{equipment.rate} </span>
                                <span className='name'>{equipment.cnName}</span>
                                <p>{equipment.description}</p>
                                <h3>可适配核心：</h3>
                                {
                                    equipment.suitablecores!.map((core:SkibidiToiletCore) =>
                                        <p key={core.id}>{core.cnName}</p>
                                    )
                                }
                                <img src={`https://oss.usecubes.cn/${equipment.image.url}`} />
                            </div>
                        )
                    }
                </div>
                
                <Divider orientation="left">英雄卡牌Category</Divider>
                <div className="cardContent">
                    {
                        this.state.heros!.map((hero:SkibidiToiletHero) =>
                            <div className='card' key={hero.id} onClick={this.addHero.bind(this,hero.id)}>
                                <span className='rate'>{hero.rate}</span>
                                <span className='name'>{hero.cnName}</span>
                                {/* <p>{hero.description}</p> */}

                                <h3>核心：</h3>
                                {
                                    hero.corecategorys!.map((core:SkibidiToiletCore) =>
                                        <p key={core.id}>{core.cnName}</p>
                                    )
                                }

                                <h3>装备：</h3>
                                {
                                    hero.equipmentToHeros!.map((equipmenttohero:equipmentToHero) =>
                                        <p key={equipmenttohero.id}>{equipmenttohero.equipmentCategory.cnName}:{equipmenttohero.equipmentCount}个</p>
                                    )
                                }
                                <img src={`https://oss.usecubes.cn/${hero.image.url}`} />
                            </div>
                        )
                    }
                </div>


                <Form.Item name="image">
                    <Upload {...{
                        name: 'cardImage',
                        method: 'PUT',
                        action: '/api/v1/skibidi_toilet_card/image/upload',
                    }}>
                        <Button>上传图片</Button>
                    </Upload>
                </Form.Item>





            </div >
        );
    }
}

export default SkibidiToiletCard;
import { Layout, Card, Upload, message, Input, Button } from 'antd';
import React from 'react';
import './index.less';
import { InboxOutlined } from '@ant-design/icons';
const { Dragger } = Upload;
const { Content } = Layout;

//货架数
const MAX_P = 7
const MAX_P_ARR: string[] = [];
for (let maxp = 1; maxp <= MAX_P; maxp++) {
    MAX_P_ARR.push(maxp + '');
}
//货架层数
const MAX_LAYER = 7;
const MAX_LAYER_ARR: string[] = [];
for (let maxl = 1; maxl <= MAX_LAYER; maxl++) {
    MAX_LAYER_ARR.push(maxl + '');
}
//货架每层数量
const MAX_LAYER_LOCATION = 15;
const MAX_LAYER_LOCATION_ARR: string[] = [];
for (let maxll = 1; maxll <= MAX_LAYER_LOCATION; maxll++) {
    if (maxll < 10) {
        MAX_LAYER_LOCATION_ARR.push('0' + maxll);
    } else {
        MAX_LAYER_LOCATION_ARR.push(maxll + '');
    }
}

const allLocations = [];
for (let i = 1; i <= MAX_P; i++) {
    ['A', 'B'].forEach((face, key) => {
        for (let k = 1; k <= MAX_LAYER; k++) {
            for (let j = 1; j <= MAX_LAYER_LOCATION; j++) {
                const location = j > 9 ? (j + '') : ('0' + j);
                allLocations.push('P' + i + '-' + face + k + location)
            }
        }
    })
}

const uploadProps = {
    name: 'excel',
    multiple: true,
    action: '/api/v1/gjp/location',
}

class Gjp extends React.Component {

    componentDidMount = () => {
    }

    state: { [key: string]: any } = {
        canuseLocations: [],
        wrongLocations: [],
    }

    onChange(info: any) {
        if (info.file && info.file.response) {
            this.setState({
                canuseLocations: info.file.response && info.file.response.availableLocations,
                wrongLocations: info.file.response && info.file.response.wrongLocations,
            }, () => {
                if (this.state.wrongLocations.length > 0) {
                    message.error('出现重复库位，请查看库位红色区域')
                }
            })
        }
    }

    render() {
        return (
            <Content className="gjp-location">
                <Card className="common-content">
                    <Dragger {...uploadProps} onChange={this.onChange.bind(this)}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">点击上传管家婆下载的库位表格</p>
                        <p className="ant-upload-hint">
                            或者直接把文件拖进来也可以哦
                        </p>
                    </Dragger>

                    {
                        MAX_P_ARR.map(p => {
                            return <div className="p">
                                {
                                    MAX_LAYER_LOCATION_ARR.map(lo => {
                                        return <div className="lo">
                                            {
                                                ['A', 'B'].map(face => {
                                                    return <div className={"face " + face}>
                                                        {
                                                            MAX_LAYER_ARR.map(layer => {
                                                                const locationName = 'P' + p + '-' + face + layer + lo
                                                                const available = this.state.canuseLocations.indexOf(locationName) > -1 ? 'available' : '';
                                                                const wrong = this.state.wrongLocations.indexOf(locationName) > -1 ? 'wrong' : '';
                                                                return <div className={"layer " + available + ' ' + wrong}>
                                                                    {locationName}
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                })
                                            }
                                        </div>
                                    })
                                }
                            </div>
                        })
                    }
                </Card>
            </Content>
        );
    }
}

class GjpOrder extends React.Component {
    componentDidMount = () => {
    }

    submit = () => {
        const theForm = document.querySelector('form');
        if (theForm) {
            const formData = new FormData(theForm)
            fetch('/api/v1/gjp/order/output', {
                method: "POST",
                body: formData
            }).then(res => {
                message.success('导出成功，正在下载')
                window.open('/api/v1/gjp/order/download_output_xlsx')
            }).catch(_ => {

            })
        }
    }


    render() {
        return (
            <Content>
                <Card className="common-content">
                    <h1>点击上传“管家婆导出订单的表格”以及“导入订单的表格”</h1>
                    <h3>两个表格一定要一起上传哦！！！</h3>
                    <br/>
                    <form action='/api/v1/gjp/order/output' method='post'>
                        <Input name="excels" type='file' multiple></Input>
                        <br/>
                        <br/>
                        <Button type="primary" onClick={this.submit}>上传并下载导出表格</Button>
                    </form>
                </Card>
            </Content>
        );
    }
}

export { GjpOrder, Gjp };
import { Layout, Menu, Typography } from 'antd';
import {
  UserOutlined
} from '@ant-design/icons';
import { Component } from 'react';
import { Route, HashRouter as Router, Switch } from 'react-router-dom';
import JDProductsEdit from './components/jd_products_edit';
import JDProductsPrint from './components/jd_products_print';
import UsecubesBarcodeEdit from './components/usecubes_barcode_edit';
import UsecubesEANcodeEdit from './components/usecubes_eancode_edit';
import UsecubesQrcode from './components/usecubes_qrcode';
import Login from './components/login';
import UsecubesAllStar from './components/usecubes-all-star';
import BackroomsCard from './components/backrooms-card';
import BackroomsLevelCard from './components/backrooms-level-card';
import SkibidiCard from './components/skibidi-card';
import { Gjp, GjpOrder } from './components/gjp';
import './App.less';

const { Sider, Content } = Layout;
const { SubMenu } = Menu;
const { Link } = Typography;


class MainLayout extends Component {
  state = {
    collapsed: false,
  };

  onCollapse = (collapsed: any) => {
    this.setState({ collapsed });
  };

  render() {
    const { collapsed } = this.state;
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse}>
          <div className="logo">
            像素屋工具
          </div>
          <Menu theme="dark" mode="inline">
            <SubMenu key="sub5" icon={<UserOutlined />} title="像素屋条码">
              <Menu.Item key="7">
                <Link href="/#/tools/usecubes_code/edit">像素屋条码维护</Link>
              </Menu.Item>
              <Menu.Item key="10">
                <Link href="/#/tools/usecubes_eancode/edit">EAN编码维护</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="sub6" icon={<UserOutlined />} title="像素屋二维码">
              <Menu.Item key="8">
                <Link href="/#/tools/usecubes_qrcode/generate">像素屋二维码</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="sub7" icon={<UserOutlined />} title="像素屋商品">
              <Menu.Item key="9">
                <Link href="/#/tools/usecubes_all_star">MC全明星卡</Link>
              </Menu.Item>
              <Menu.Item key="11">
                <Link href="/#/tools/backrooms_card">后室卡牌</Link>
              </Menu.Item>
              <Menu.Item key="backrooms_level_card">
                <Link href="/#/tools/backrooms_level_card">后室层级卡牌</Link>
              </Menu.Item>
              <Menu.Item key="12">
                <Link href="/#/tools/skibidi_card">马桶人卡牌</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="sub1" icon={<UserOutlined />} title="京东条码打印服务">
              <Menu.Item key="1">
                <Link href="/#/tools/jd_products/print">京东条码打印</Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link href="/#/tools/jd_products/edit">京东条码维护</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="sub3" icon={<UserOutlined />} title="管家婆库位管理">
              <Menu.Item key="5">
                <Link href="/#/tools/gjp">可用库位查询</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="sub4" icon={<UserOutlined />} title="管家婆订单处理">
              <Menu.Item key="6">
                <Link href="/#/tools/gjp_order">订单导出后处理 </Link>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>
        <Content>
          {this.props.children}
        </Content>
      </Layout>
    );
  }
}

class EmptyLayout extends Component {
  render() {
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Content>
          {this.props.children}
        </Content>
      </Layout>
    );
  }
}

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact render={() => {return <MainLayout><UsecubesBarcodeEdit/></MainLayout>}} path="/tools/usecubes_code/edit" />
          <Route exact render={() => {return <MainLayout><UsecubesEANcodeEdit/></MainLayout>}} path="/tools/usecubes_eancode/edit" />
          <Route exact render={() => {return <MainLayout><UsecubesQrcode/></MainLayout>}} path="/tools/usecubes_qrcode/generate" />
          <Route exact render={() => {return <MainLayout><JDProductsPrint/></MainLayout>}} path="/tools/jd_products/print" />
          <Route exact render={() => {return <MainLayout><JDProductsEdit/></MainLayout>}} path="/tools/jd_products/edit" />
          <Route exact render={() => {return <MainLayout><UsecubesAllStar/></MainLayout>}} path="/tools/usecubes_all_star" />
          <Route exact render={() => {return <MainLayout><BackroomsCard/></MainLayout>}} path="/tools/backrooms_card" />
          <Route exact render={() => {return <MainLayout><BackroomsLevelCard/></MainLayout>}} path="/tools/backrooms_level_card" />
          <Route exact render={() => {return <MainLayout><SkibidiCard/></MainLayout>}} path="/tools/skibidi_card" />
          <Route exact render={() => {return <MainLayout><Gjp/></MainLayout>}} path="/tools/gjp" />
          <Route exact render={() => {return <MainLayout><GjpOrder/></MainLayout>}} path="/tools/gjp_order" />
          <Route exact render={() => {return <EmptyLayout><Login/></EmptyLayout>}} path="/login" />
          <Route exact render={() => {return <MainLayout><JDProductsPrint/></MainLayout>}} path="" />
        </Switch>
      </Router>
    );
  }
}

export default App;
import { Component } from 'react';
import JsBarcode from 'jsbarcode';


interface InjectedProps {
  value: string
  format: string
  width: number
  height: number
  displayValue: boolean
  textAlign: string
  textPosition: string
  textMargin: number
  fontSize: number
  background: string
  lineColor: string
  margin: number
  marginBottom: number
}

export default class Barcode extends Component<InjectedProps> {

  static defaultProps = {
    format: 'CODE128',
    width: 1.6,
    height: 25,
    displayValue: true,
    textAlign: 'center',
    textPosition: 'bottom',
    textMargin: 6,
    fontSize: 14,
    background: '#ffffff',
    lineColor: '#000000',
    margin: 0,
    marginBottom: 0,
  };

  constructor(props: InjectedProps) {
    super(props);
    this.update = this.update.bind(this);
  }

  barcode: string = "";

  componentDidMount() {
    this.update();
  }

  componentDidUpdate() {
    this.update();
  }

  handleBarcode = (r: any) => {
    this.barcode = r;
  };

  update() {
    const {
      value,
      format,
      width,
      height,
      displayValue,
      textAlign,
      textPosition,
      textMargin,
      fontSize,
      background,
      margin,
      lineColor,
      marginBottom,
    } = this.props;
    JsBarcode(this.barcode, value, {
      format,
      width,
      height,
      displayValue,
      textAlign,
      textPosition,
      textMargin,
      fontSize,
      background,
      margin,
      lineColor,
      marginBottom,
    });
  }

  render() {
    return <svg ref={this.handleBarcode} />;
  }
}

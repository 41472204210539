import { Layout, Modal, Input, Button } from 'antd';
import React from 'react';
import './index.less';
import { getData, } from '../../utils/fetch'
import { QRCodeCanvas } from 'qrcode.react';
import { ajax } from 'jquery'
import {
    QrcodeOutlined,
} from '@ant-design/icons';
import { info } from 'console';
const { Content } = Layout;

class UsecubesQrcode extends React.Component {

    state: { [key: string]: any } = {
        id: '',
        barcode: '',
        showModal: true,
        manualLink: '',
        modelInfo: undefined,
    }

    async readAndMatchId(text: string) {
        if (/^[c|g]{0,1}\d{2,}\.?\d{0,}/.test(text)) {
            const modelIDArr = text.match(/[c|g]{0,1}\d{2,}\.?\d{0,}/)
            const modelIDorGroupID = modelIDArr && modelIDArr[0]
            this.setState({
                id: modelIDorGroupID
            }, () => {
                this.getManualLink(this.state.id)
            })
        } else if (/(FP|fp)(\d+)/.test(text)) {
            const FPcodeArr = text.match(/(FP|fp)(\d+)/)
            const FPcode = FPcodeArr && FPcodeArr[2]
            const skuCodeInfo = await ajax({
                method: "GET",
                url: `https://usecubes.cn/getfpcode?id=${FPcode}&adminTokenFromPixelhouse=usecubes-pixelhouse-generate-code`,
                dataType: 'JSON',
                xhrFields: {
                    withCredentials: true
                }
            })
            const skuCode = skuCodeInfo[0].code; // [1+c31580] [1+g360]之类的，后面也许要支持两个id的套餐
            // const skuCode = "[1+c31580]";
            const modelIDorGroupID = skuCode.match(/[c|g]{0,1}\d{2,}(\.?\d+)?/)![0];
            this.readAndMatchId(modelIDorGroupID)
        } else if (/BL\d+/.test(text)) {
            const barcodeArr = text.match(/BL\d+/)
            const barcode = barcodeArr && barcodeArr[0]
            const modelIDorGroupID = await getData('/api/v1/usecubes_barcode/usecubes_id', new Map([['barcode', String(barcode)]]));
            this.setState({
                barcode: barcode,
                id: modelIDorGroupID.usecubesId
            }, () => {
                this.getManualLink(this.state.id)
            })
        }
    }

    getQRcode(value: string) {
        this.readAndMatchId(value);
    }

    async getManualLink(id: string) {
        if (id[0].toLowerCase() === 'c') {
            let modelID, modelVersion, modelInfo;
            if (id.indexOf('.') < 0) {
                const versionArr = id.match(/c?\d+/);
                modelID = versionArr![0];
                modelID = modelID.replace('c', '10000')
                modelInfo = await ajax({
                    method: "GET",
                    url: `https://usecubes.cn/model?id=${modelID}`,
                    dataType: 'JSON',
                    xhrFields: {
                        withCredentials: true
                    }
                })
                modelVersion = modelInfo.version;
            } else {
                const versionArr = id.match(/(c?\d+)\.(\d+)/);
                modelID = versionArr![1];
                modelID = modelID.replace('c', '10000')
                modelVersion = versionArr![2];
                modelInfo = await ajax({
                    method: "GET",
                    url: `https://usecubes.cn/model?id=${modelID}`,
                    dataType: 'JSON',
                    xhrFields: {
                        withCredentials: true
                    }
                })
            }
            const code = await ajax({
                method: "GET",
                url: `https://usecubes.cn/block/generateCode?id=${modelID}&version=${modelVersion}&adminTokenFromPixelhouse=usecubes-pixelhouse-generate-code`,
                dataType: 'JSON',
                xhrFields: {
                    withCredentials: true
                }
            })
            const manualLink = 'https://usecubes.cn/m/' + modelID + '/' + modelVersion + '?code=' + code.code
            this.setState({
                showModal: true,
                manualLink,
                modelInfo
            })
        } else {
            const groupArr = id.match(/g(\d+)/);
            const groupID = groupArr![1];
            const groupInfo = await ajax({
                method: "GET",
                url: `https://usecubes.cn/group?id=${groupID}`,
                dataType: 'JSON',
                xhrFields: {
                    withCredentials: true
                }
            })
            const models = groupInfo.models;
            let modelID = models.split(':')[0];
            modelID = modelID.replace('c', '10000')
            const modelInfo = await ajax({
                method: "GET",
                url: `https://usecubes.cn/model?id=${modelID}`,
                dataType: 'JSON',
                xhrFields: {
                    withCredentials: true
                }
            })
            const code = await ajax({
                method: "GET",
                url: `https://usecubes.cn/block/generateCode?group=${groupID}&adminTokenFromPixelhouse=usecubes-pixelhouse-generate-code`,
                dataType: 'JSON',
                xhrFields: {
                    withCredentials: true
                }
            })
            const manualLink = 'https://usecubes.cn/collect/' + groupID + '?code=' + code.code
            this.setState({
                showModal: true,
                manualLink,
                modelInfo
            })
        }
    }

    closeModal = () => {
        this.setState({
            showModal: false
        })
    }

    showModal = () => {
        this.setState({
            showModal: true
        })
    }

    download = () => {
        const link = document.createElement('a');
        const canvas = document.querySelector('canvas') as HTMLCanvasElement
        link.download = 'qrcode.png';
        link.href = canvas.toDataURL();
        link.click();
    }

    closeQrContent = () => {
        this.setState({
            showModal: false
        })
    }

    render() {
        return (
            <Content>
                <Button onClick={() => {this.setState({showModal:true})}}>打开</Button>
                <div className={this.state.showModal ? "qr-content" : "qr-content close"}>
                    <Input.Search placeholder='粘贴条码或者编码' enterButton={<QrcodeOutlined />} onSearch={this.getQRcode.bind(this)} />
                    <div className="qr-area">
                        {this.state.manualLink !== '' && this.state.modelInfo
                            &&
                            <div className='model-info'>
                                <p className="name">{this.state.modelInfo && this.state.modelInfo.title}</p>
                                <img src={`//cdn.usecubes.cn/block/pic/${this.state.modelInfo.id}/${this.state.modelInfo.version}`} alt={this.state.modelInfo.title} />
                            </div>
                        }
                        {this.state.manualLink !== '' && <QRCodeCanvas className='qrcode' value={this.state.manualLink} />}
                    </div>
                    <Button onClick={this.closeQrContent}>关闭</Button>
                </div>

            </Content >
        );
    }
}

export default UsecubesQrcode;
import {
    LoadingOutlined,
    UploadOutlined 
} from '@ant-design/icons'
import { 
    Layout, 
    Divider, 
    Input, 
    InputNumber, 
    Dropdown,
    Menu,
    Button, 
    message,
    Upload,
    Form,
    FormInstance,
    Radio
} from 'antd';
import React from 'react';
import './index.less';

import * as XLSX from "xlsx";
import { ajax } from 'jquery';
import axios from 'axios';

const { Content } = Layout;
const { TextArea} = Input;


const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
interface AllStarManCategory {
    id:number,
    no:string,
    rate:string,
    attack:string,
    control:string,
    defense:string,
    speed:string,
    cnName:string,
    name:string
}
interface AllStarMan {
    id:number,
    created: Date;
    updated:Date;
    category:AllStarManCategory,
    userId:string,
    merged: boolean;
    position: number;
    level: number;
    awakening: boolean;
    intelligent: boolean;
    attackPlus: number;
    defensePlus: number;
    activeDate: Date;
}

const layout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 16 },
};

// const DEFAULT_HP = 100;

function formatId (id:number|string, length:number){
    let num = "000000000000000000000000000000000000000" + String(id);
    return num.substring(num.length - length);
}


class UsecubesAllStar extends React.Component {
    

    formRef = React.createRef<FormInstance>();
    roleForm = React.createRef<FormInstance>();
    outlookForm = React.createRef<FormInstance>();
    individualForm = React.createRef<FormInstance>();
    intelligentForm = React.createRef<FormInstance>();
    
    state: { [key: string]: any } = {
        manCategories:[],
        generateMen:[],
        xlsxBlobs:[],
        roleData:{},
        outlookData:{},
        individualData:{},
        manIndividualData:{},
        scriptTemplateData:{},
        enName:"",
        cnName:""
    }

    componentDidMount = () => {
        this.loadManCategories();
        this.fetchIntelligent();
    }


    async loadManCategories(){
        
        const data = await ajax({
            method: "GET",
            url: `/api/v1/usecubes_all_star/man/categories`
        });

        this.setState({
            manCategories:data.list
        });

        return data.list;
    }

    getCategory(id:string){
        for(const category of this.state.manCategories){
            if(parseInt(category.no, 10) === parseInt(id, 10)){
                return category
            }
        }
    }

    async requestAndGenerate(id:string|number, count:string){

        const addresses = await ajax({
            method: "PUT",
            url: `/api/v1/usecubes_all_star/address`,
            data: {
                manCategory:id,
                count:count
            }
        });

        const values = this.formRef.current && this.formRef.current.getFieldsValue();

            
        let data = [
            [
                "图案编号", 
                "可变数据：ID", 
                "可变数据：二维码"
            ]
        ];

        let idIndex = parseInt(values.startId, 10);

        data.push.apply(data,
            addresses.list.map((data: any) => {
                return [
                    `${formatId(data.category.no, 3)}`, 
                    `ID:${formatId(idIndex++, 4)}`, 
                    `https://usecubes.cn/as/${data.address}`
                ]
            })
        );

          //Create a new Work Sheet using the data stored in an Array of Arrays.
        const workSheet = XLSX.utils.aoa_to_sheet(data);
        // Generate a Work Book containing the above sheet.
        const workBook = {
            Sheets: { data: workSheet, cols: [] },
            SheetNames: ["data"],
        };
        // Exporting the file with the desired name and extension.
        const excelBuffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });
        const fileData = new Blob([excelBuffer], { type: fileType });

        return URL.createObjectURL(fileData);

    }

    async continueRequestAndGenerate(count:string, categories:number[], generateIndex:number = 0):Promise<any>{
        const categoryId = categories[generateIndex];
        const category = this.getCategory(categoryId.toString());
        return this.requestAndGenerate(category.id, count)
            .then(async (blob) => {
                const nextIndex = generateIndex + 1;
                if(categories[nextIndex]){
                    await this.continueRequestAndGenerate(count, categories, nextIndex);
                }
                this.state.xlsxBlobs.push(blob)
                this.state.generateMen.push(category)
                return blob;
            })
        
    }


    async generateQRCodes(){
        this.setState({
            generateMen:[],
            xlsxBlobs:[],
        });
        const form = this.formRef.current;
        
        if(form){


            const values = form.getFieldsValue();

            if(values.continues){
                const singleIds:number[] = [];
                const ids = singleIds.concat.apply(
                    singleIds,
                    values.continues.split(",")
                        .map((idRange:string) => {
                            if(idRange.indexOf("-") > -1){
                                const idStart = parseInt(idRange.split("-")[0], 10);
                                const idEnd = parseInt(idRange.split("-")[1], 10);
                                const ids = [];
                                let i = idStart;
                                for(; i<=idEnd; i++){
                                    ids.push(i);
                                }
                                return ids;
                            }
                            else{
                                return [parseInt(idRange, 10)];
                            }
                        })
                )
                await this.continueRequestAndGenerate(values.count, ids);
            }
            else{

                // 这里是之前选择的。
                // this.state.xlsxBlobs.push(await this.requestAndGenerate(values.manCategory.id, values.count));
                // this.state.generateMen.push(values.manCategory);
            }
            this.forceUpdate();


        }
    }

    async dumpRoleData(){
        
        const values = this.roleForm.current!.getFieldsValue();

        try{
            if(this.state.roleData.embedding){
                this.state.roleData.embeddingPushLoading = true;
                this.forceUpdate();
                if(this.state.roleManCategory){
                    const result = await axios.put(`/api/v1/usecubes_all_star/memory/role`, {
                        categoryId:this.state.roleManCategory.id,
                        memArray:this.state.roleData.embedding.split("---")
                    });                    
                    this.state.roleData.embedding = result.data && result.data.sets ? null : this.state.roleData;
                }
                else{
                    alert("别忘了选角色")
                }
                this.state.roleData.embeddingPushLoading = false;
                this.forceUpdate();
            }
            else if(values.text){
                this.state.roleData.embedLoading = true;
                
                this.state.roleData.embedding = null;
                this.forceUpdate();
                if(this.state.roleManCategory){
                    const result = await axios.post(`/api/v1/usecubes_all_star/profile/role`, {
                        categoryId:this.state.roleManCategory.id,
                        text:values.text
                    });                
                    this.state.roleData.embedding = result.data;
                }
                else{
                    alert("别忘了选角色")
                }
                
                this.state.roleData.embedLoading = false;
                this.forceUpdate();
            }

            
            this.state.roleManCategory && this.selectRoleManCategory(this.state.roleManCategory)
        }
        catch(e){
            console.error(e);
            this.state.roleData.embedLoading = false;
            this.forceUpdate();
        }
    }

    async dumpOutlookData(){
        
        const values = this.outlookForm.current!.getFieldsValue();

        try{
            if(this.state.outlookData.embedding){
                this.state.outlookData.embeddingPushLoading = true;
                this.forceUpdate();
                const result = await axios.put(`/api/v1/usecubes_all_star/memory/outlook`, {
                    memArray:this.state.outlookData.embedding.split("---")
                });
                
                this.state.outlookData.embeddingPushLoading = false;
                this.state.outlookData.embedding = result.data && result.data.sets ? null : this.state.outlookData;
                this.forceUpdate();
            }
            else if(values.text){
                this.state.outlookData.embedLoading = true;
                this.state.outlookData.embedding = null;
                this.forceUpdate();
                const result = await axios.post(`/api/v1/usecubes_all_star/profile/outlook`, {
                    text:values.text
                });
                
                this.state.outlookData.embedLoading = false;
                this.state.outlookData.embedding = result.data;
                this.forceUpdate();

            }

        }
        catch(e){
            console.error(e);
            this.state.outlookData.embedLoading = false;
            this.forceUpdate();
        }
    }
    async generateAIManWithIndividualData(){
        
        // const values = this.individualForm.current!.getFieldsValue();

        try{
            if(this.state.aiManCategory){

                this.state.individualData.embedLoading = true;
                // this.state.individualData.embedding = null;
                this.forceUpdate();
                await axios.put(`/api/v1/usecubes_all_star/man`, {
                    categoryId:this.state.aiManCategory.id
                });
                
                this.state.individualData.embedLoading = false;
                // this.state.individualData.embedding = result.data;
                this.forceUpdate();
                this.fetchIntelligent();
            }
            else{
                
                alert("别忘了选角色")
            }
        }
        catch(e){
            console.error(e);
            this.state.individualData.embedLoading = false;
            this.forceUpdate();
        }
    }

    fetchIntelligent(){
        axios.get(`/api/v1/usecubes_all_star/intelligent`)
            .then(result => {
                this.setState({
                    intelligentMen:result.data!.list
                })
            })
            .catch(e => {
                this.setState({
                    intelligentMen:null
                })
            })
    }



    onChangeContinue(){

    }

    selectAiManCategory(category:AllStarManCategory){
        this.setState({
            aiManCategory:category
        })
    }

    selectRoleManCategory(category:AllStarManCategory){
        this.setState({
            roleManCategory:category
        }, async () => {
            
            const list = (await axios.get(`/api/v1/usecubes_all_star/role/memories`, {
                params:{
                    id:category.id
                }
            })).data;
            
            this.state.roleManCategory.roleMemories = list;
            this.forceUpdate();
        })
    }

    async updateIntelligent(){

        const values = this.intelligentForm.current!.getFieldsValue();

        try{
            if(values.more && values.man){

                this.state.manIndividualData.embedLoading = true;
                this.state.manIndividualData.embedding = "";
                this.forceUpdate();
                await axios.post(`/api/v1/usecubes_all_star/man/individuals`, {
                    individual:values.more,
                    manId:values.man.id
                });
                
                this.state.manIndividualData.embedLoading = false;
                this.forceUpdate();
                this.selectIntelligent(values.man);
            }
            else{
                
                alert("别忘了选角色")
            }
        }
        catch(e){
            console.error(e);
            this.state.individualData.embedLoading = false;
            this.forceUpdate();
        }
    }

    async selectIntelligent(man:AllStarMan){
        this.setState({
            selectedIntelligentMan:{man}
        }, async () => {
            const list = (await axios.get(`/api/v1/usecubes_all_star/man/individuals`, {
                params:{
                    id:man.id
                }
            })).data;
            
            this.state.selectedIntelligentMan.individuals = list;
            this.forceUpdate();
        })
    }

    async removeIndividualForMan(man:AllStarMan, individual:{id:string, text:string}, e:any){
        e.preventDefault && e.preventDefault()
        e.stopPropagation && e.stopPropagation()
        
        await axios.delete(`/api/v1/usecubes_all_star/man/individuals`, {
            params:{
                ids:[individual.id.replace("individual:", "")]
            }
        })
        return this.selectIntelligent(man);
    }

    async removeMemoryForCategory(category:AllStarManCategory, memory:{id:string, text:string}){
        await axios.delete(`/api/v1/usecubes_all_star/role/memories`, {
            params:{
                ids:[memory.id.replace("role:", "")]
            }
        })
        return this.selectRoleManCategory(category);
    }


    render() {

        return (

            <Content className='usecubes-all-star'>
                <Divider orientation="left">生成卡牌</Divider>
                <div className='all-star-category'>
                    <Form {...layout} ref={this.formRef} name="control-hooks" onFinish={this.generateQRCodes.bind(this)}>
                        <Form.Item label="角色" name="manCategory" >
                            <Radio.Group>
                            {
                                this.state.manCategories!.map((category:AllStarManCategory) => 
                                    <Radio.Button className='roleButton' key={category.id} value={category}>
                                        <span className='rate'>{category.rate}</span>
                                        <span className='no'>{formatId(category.no, 3)} </span>
                                        <span className='name'>{category.cnName || category.name}</span>
                                    </Radio.Button>
                                )
                            }
                            </Radio.Group>
                        </Form.Item>
                        
                        <Form.Item name="count" label="数量" rules={[{ required: true }]}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item name="startId" label="起始ID" rules={[{ required: true }]} >
                            <InputNumber placeholder='0000'/>
                        </Form.Item>
                        <Form.Item name="continues" label="连续生成编号" >
                            <Input placeholder='001, 008, 019-035, ...'/>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                            生成卡牌二维码数据
                            </Button>          
                        </Form.Item>  

                            
                        {
                            this.state.generateMen && !!this.state.generateMen.length && 
                            this.state.generateMen.map((man: any, index:number) => {
                                const values = this.formRef.current && this.formRef.current.getFieldsValue();
                                const startId = ~~(values && values.startId);
                                const count = ~~(values && values.count);
                                const endId = startId + count - 1;
                                const downloadName = `No.${formatId(man && man.no, 3)}_${man && man.name}_ID:${formatId(startId, 4)}-ID:${formatId(endId, 4)}_${count}(PCS).xlsx`;
                                return [<a download={downloadName} key={index} href={this.state.xlsxBlobs[index]}>{downloadName}</a>,<br/>]
                            })
                        }
                    </Form>
                </div>
                <Divider orientation="left">上传脚本模板</Divider>
                <div className='all-star-category'>
                    
                    <Upload {...{
                        name: 'template',
                        method:'PUT',
                        action: '/api/v1/usecubes_all_star/next/script',
                        onChange(info) {
                            if (info.file.status !== 'uploading') {
                                console.log(info.file, info.fileList);
                            }
                            if (info.file.status === 'done') {
                                message.success(`${info.file.name} file uploaded successfully`);
                            } else if (info.file.status === 'error') {
                                message.error(`${info.file.name} file upload failed.`);
                            }
                        },
                    }}>
                        <Button icon={<UploadOutlined />}>上传，[脚本模板id].json</Button>
                    </Upload>
                </div>
                <Divider orientation="left">推送角色人格</Divider>
                <div className='all-star-category'>
                    <Form {...layout} ref={this.roleForm} name="control-hooks" onFinish={this.dumpRoleData.bind(this)}>
                        <Dropdown  
                            overlay={
                                <Menu>
                                    {
                                        this.state.manCategories!.map((category:AllStarManCategory) => 
                                            <Menu.Item key={category.id}>
                                                <Button className='roleButton'
                                                    onClick={this.selectRoleManCategory.bind(this, category)}>
                                                    <span className='rate'>{category.rate}</span>
                                                    <span className='no'>{formatId(category.no, 3)} </span>
                                                    <span className='name'>{category.cnName || category.name}</span>
                                                </Button>
                                            </Menu.Item>
                                        )                                
                                    }
                                </Menu>
                            } 
                            trigger={['click']}
                            placement="topLeft"
                            arrow={true}>
                            {
                                this.state.roleManCategory ? 
                                    <Button className='roleButton'>
                                        <span className='rate'>{this.state.roleManCategory.rate}</span>
                                        <span className='no'>{formatId(this.state.roleManCategory.no, 3)} </span>
                                        <span className='name'>{this.state.roleManCategory.cnName || this.state.aiManCategory.name}</span>
                                    </Button>
                                    :
                                    <Button>选择角色</Button>
                            }
                        </Dropdown>

                        
                        {this.state.roleManCategory && 
                            
                            <div className='roleManCategory'>
                                全部人格：
                                <div className='roleData'>
                                    {this.state.roleManCategory.roleMemories && 
                                        this.state.roleManCategory.roleMemories.map(
                                            (memory:{id:string, text:string}, index:number) => 
                                                <div className='memory' key={index}>
                                                    {memory.text}
                                                    <button className='dele' 
                                                        onClick={this.removeMemoryForCategory.bind(this, 
                                                            this.state.roleManCategory, 
                                                            memory)
                                                        }>x</button>
                                                </div>
                                        )}
                                </div>
                            </div>
                        }
                        
                        <Form.Item name="text" label="数据源" rules={[{ required: true }]}>
                            <TextArea rows={4} placeholder="角色介绍，性格，台词，故事"/>
                        </Form.Item>
                        {
                            this.state.roleData && this.state.roleData.embedLoading ? 
                            <LoadingOutlined />
                            :
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                AI侧写
                                </Button>          
                            </Form.Item>

                        }
                        {
                            this.state.roleData && this.state.roleData.embedding &&
                            <div>
                                <TextArea value={this.state.roleData.embedding} onChange={
                                    (e) => {
                                        this.state.roleData.embedding = e.target.value;
                                        this.forceUpdate();
                                    }
                                } rows={4} placeholder="等待AI侧写完成"/> 
                                {
                                    this.state.roleData.embeddingPushLoading ? 
                                    <LoadingOutlined />
                                    :
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                        推送到系统
                                        </Button>
                                    </Form.Item>
                                }
                            </div>
                        }
                    </Form>
                </div>
                <Divider orientation="left">推送“次世界”世界观</Divider>
                <div className='all-star-category'>
                    <Form {...layout} ref={this.outlookForm} name="control-hooks" onFinish={this.dumpOutlookData.bind(this)}>
                        
                        <Form.Item name="text" label="数据源" rules={[{ required: true }]}>
                            <TextArea rows={4} placeholder="世界观设定，背景故事，调查信息等等"/>
                        </Form.Item>
                        {
                            this.state.outlookData && this.state.outlookData.embedLoading ? 
                            <LoadingOutlined />
                            :
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                AI侧写
                                </Button>          
                            </Form.Item>

                        }
                        {
                            this.state.outlookData && this.state.outlookData.embedding &&
                            <div>
                                <TextArea value={this.state.outlookData.embedding} onChange={
                                    (e) => {
                                        this.state.outlookData.embedding = e.target.value;
                                        this.forceUpdate();
                                    }
                                } rows={4} placeholder="等待AI侧写完成"/> 
                                {
                                    this.state.outlookData.embeddingPushLoading ? 
                                    <LoadingOutlined />
                                    :
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                        推送到系统
                                        </Button>          
                                    </Form.Item>
                                }
                            </div>
                        }
                    </Form>
                </div>
                <Divider orientation="left">生成“次世界”AI克隆体</Divider>
                <div className='all-star-category'>
                    <Form {...layout} ref={this.individualForm} name="control-hooks" onFinish={this.generateAIManWithIndividualData.bind(this)}>
                                
                        <Dropdown  overlay={
                                <Menu>
                                    {
                                        this.state.manCategories!.map((category:AllStarManCategory) => 
                                            <Menu.Item key={category.id}>
                                                <Button className='roleButton'
                                                    onClick={this.selectAiManCategory.bind(this, category)}>
                                                    <span className='rate'>{category.rate}</span>
                                                    <span className='no'>{formatId(category.no, 3)} </span>
                                                    <span className='name'>{category.cnName || category.name}</span>
                                                </Button>
                                            </Menu.Item>
                                        )                                
                                    }
                                </Menu>
                            } 
                            trigger={['click']}
                            placement="topLeft"
                            arrow={true}>
                                {
                                    this.state.aiManCategory ? 
                                        <Button className='roleButton'>
                                            <span className='rate'>{this.state.aiManCategory.rate}</span>
                                            <span className='no'>{formatId(this.state.aiManCategory.no, 3)} </span>
                                            <span className='name'>{this.state.aiManCategory.cnName || this.state.aiManCategory.name}</span>
                                        </Button>
                                        :
                                        <Button>选择角色</Button>
                                }
                        </Dropdown>
                        {
                            this.state.individualData && this.state.individualData.embedLoading ? 
                            <LoadingOutlined />
                            :
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                生成
                                </Button>          
                            </Form.Item>

                        }
                        {
                            this.state.individualData && this.state.individualData.embedding &&
                            <div>
                                <TextArea value={this.state.individualData.embedding} onChange={
                                    (e) => {
                                        this.state.individualData.embedding = e.target.value;
                                        this.forceUpdate();
                                    }
                                } rows={4} placeholder="等待AI侧写完成"/> 
                            </div>
                        }
                    </Form>
                </div>
                
                <Divider orientation="left">全部AI克隆体</Divider>
                <div className='all-star-category'>
                    <Form {...layout} ref={this.intelligentForm} name="control-hooks" onFinish={this.updateIntelligent.bind(this)}>
                        <Form.Item label="活跃中" name="man" >
                            <Radio.Group>
                            {
                                this.state.intelligentMen && 
                                Array.prototype.concat.call(
                                    [], 
                                    this.state.intelligentMen
                                        .filter((man:AllStarMan) => !man.userId)
                                        .sort((a:AllStarMan, b:AllStarMan) => (~~a.category.id - ~~b.category.id > 0 ? 1 : -1))
                                        .map((man:AllStarMan) => 
                                            <Radio.Button className='roleButton withId' key={man.id} value={man}
                                                onClick={this.selectIntelligent.bind(this, man)}>
                                                <span className='rate'>{man.category.rate}</span>
                                                <span className='no'>{formatId(man.category.no, 3)} </span>
                                                <span className='name'>{man.category.cnName || man.category.name}-{man.id}</span>
                                                
                                            </Radio.Button>
                                        ),
                                    [<div className='br'></div>],
                                    this.state.intelligentMen
                                        .filter((man:AllStarMan) => !!man.userId)
                                        .sort((a:AllStarMan, b:AllStarMan) => (~~a.userId - ~~b.userId > 0 ? 1 : -1))
                                        .map((man:AllStarMan) => 
                                            <Radio.Button className='roleButton withId' key={man.id} value={man}
                                                onClick={this.selectIntelligent.bind(this, man)}>
                                                <span className='rate'>{man.category.rate}</span>
                                                <span className='no'>{formatId(man.category.no, 3)} </span>
                                                <span className='name'>{man.category.cnName || man.category.name}-{man.id}</span>
                                                <span className='more'>@{man.userId}</span>
                                            </Radio.Button>
                                        ),
                                )

                            }
                            </Radio.Group>
                        </Form.Item>
                        {this.state.selectedIntelligentMan && 
                        
                            <div className='selectedMan'>
                                全部个性：
                                <div className='individuals'>
                                    {this.state.selectedIntelligentMan.individuals && 
                                        this.state.selectedIntelligentMan.individuals.map(
                                            (individual:{id:string, text:string}, index:number) => 
                                                <div className='individual' key={index}>
                                                    {individual.text}
                                                    {
                                                        this.state.selectedIntelligentMan.man && 
                                                        !this.state.selectedIntelligentMan.man.userId ?
                                                        <button className='dele' 
                                                            onClick={this.removeIndividualForMan.bind(this, 
                                                                this.state.selectedIntelligentMan.man, 
                                                                individual)
                                                            }>x</button>
                                                        :
                                                        null
                                                    }
                                                </div>
                                        )}
                                </div>
                            </div>
                        }
                        {
                            this.state.selectedIntelligentMan &&
                            !this.state.selectedIntelligentMan.man.userId && 
                            <div className='pushMore'>
                                <Form.Item name="more" label="个性" rules={[{ required: true }]}>
                                    <TextArea rows={4} placeholder="克隆体的个性，记忆总结等等" 
                                        value={this.state.manIndividualData.embedding} onChange={
                                        (e) => {
                                            this.state.manIndividualData.embedding = e.target.value;
                                            this.forceUpdate();
                                        }
                                    }/>
                                </Form.Item>
                                {
                                    this.state.manIndividualData && this.state.manIndividualData.embedLoading ? 
                                    <LoadingOutlined />
                                    :
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            加推个性
                                        </Button>          
                                    </Form.Item>  
                                }
                            </div>
                        }
                    </Form>
                </div>
            </Content>
        );
    }
}

export default UsecubesAllStar;
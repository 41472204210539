import { Layout, Card, Input, Space, Button, Table, Modal, Form, message } from 'antd';
import React from 'react';
import './index.less';
import { FormInstance } from 'antd/lib/form';
import { getData, postData, updateData } from '../../utils/fetch'
import { JdProductIf } from '../../../../server/src/jd-product/jd-product.interface'

const { Content } = Layout;
const { Search } = Input;

class JDProductsEdit extends React.Component {

    componentDidMount = () => {
        this.getList();
    }

    state: { [key: string]: any } = {
        products: [],
        createProductModalVisiable: false,
        updateProductModalVisiable: false,
    }

    updatingProduct?: JdProductIf

    createProductFormRef = React.createRef<FormInstance>();
    updateProductFormRef = React.createRef<FormInstance>();

    productColumns = [
        {
            title: '商品名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '商品编码',
            dataIndex: 'usecubesId',
            key: 'usecubesId',
        },
        {
            title: '京东条形码',
            dataIndex: 'jdSkuId',
            key: 'jdSkuId',
        },
        {
            title: '京东EMG编码',
            dataIndex: 'jdEmgId',
            key: 'jdEmgId',
        },
        {
            title: '操作',
            key: 'action',
            render: (record: JdProductIf) => {
                return <Button onClick={this.toggleModal.bind(this, "updateProductModalVisiable", record)}>Edit</Button>
            }
        },
    ]

    onSearch = (nameOrUsecubesId: string) => {
        this.getList(new Map([
            ['keywords', nameOrUsecubesId]
        ]))
    }

    toggleModal = (modalName: string, product?: JdProductIf) => {
        const toggleModalObj: { [key: string]: any } = {}
        toggleModalObj[modalName] = !!!this.state[modalName]
        this.setState(Object.assign({}, this.state, toggleModalObj), () => {
            if (product) {
                this.updatingProduct = product;
                this.updateProductFormRef.current?.setFieldsValue(product)
            }
        })
    }

    getList = async (params?: Map<string, string>) => {
        let url = '/api/v1/jd_product/list';
        const products = await getData(url, params)
        this.setState({
            products: products.list
        })
    }

    createProduct = async () => {
        const createProductForm = this.createProductFormRef.current;
        if (createProductForm) {
            createProductForm.validateFields().then(async value => {
                try {
                    const postResData = await postData('/api/v1/jd_product', value)
                    if (postResData.statusCode >= 400) {
                        message.error(postResData.message)
                    } else {
                        this.setState({
                            createProductModalVisiable: false,
                        }, () => {
                            this.createProductFormRef.current?.resetFields()
                            this.getList();
                        })
                    }

                } catch {
                    //请求错误
                }
            });
        }
    }

    updateProduct = async () => {
        const updateProductForm = this.updateProductFormRef.current;
        if (updateProductForm && this.updatingProduct) {
            updateProductForm.validateFields().then(async value => {
                try {
                    const postResData = await updateData('/api/v1/jd_product', Object.assign({}, value, { id: this.updatingProduct!.id }))
                    if (postResData.statusCode >= 400) {
                        message.error(postResData.message)
                    } else {
                        this.setState({
                            updateProductModalVisiable: false,
                        }, () => {
                            this.updateProductFormRef.current?.resetFields()
                            this.getList();
                        })
                    }

                } catch {
                    //请求错误
                }
            });
        }
    }

    render() {
        return (
            <Content>
                <Card className="jd-products-content">
                    <Space size="large" direction="vertical" style={{ width: "100%" }}>
                        <Space>
                            <Search size='large' placeholder="查询商品id、名称" allowClear onSearch={this.onSearch} style={{ width: '500px' }} enterButton />
                            <Button type='primary' size='large' onClick={this.toggleModal.bind(this, "createProductModalVisiable", undefined)}>新增商品</Button>
                        </Space>
                        <Table dataSource={this.state.products} columns={this.productColumns} pagination={false} />
                    </Space>
                </Card>
                <Modal title="新增商品" visible={this.state.createProductModalVisiable} onOk={this.createProduct} cancelText="取消" okText="提交" onCancel={this.toggleModal.bind(this, "createProductModalVisiable", undefined)}>
                    <Form name="createProductForm" ref={this.createProductFormRef} size="large">
                        <Form.Item name={'name'} label="商品名称" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={'usecubesId'} label="商品编码" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={'jdEmgId'} label="EMG编码" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={'jdSkuId'} label="京东条码" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal title="更新商品" visible={this.state.updateProductModalVisiable} onOk={this.updateProduct} cancelText="取消" okText="提交" onCancel={this.toggleModal.bind(this, "updateProductModalVisiable", undefined)}>
                    <Form name="createProductForm" ref={this.updateProductFormRef} size="large">
                        <Form.Item name={'name'} label="商品名称" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={'usecubesId'} label="商品编码" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={'jdEmgId'} label="EMG编码" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={'jdSkuId'} label="京东条码" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Form>
                </Modal>
            </Content>
        );
    }
}

export default JDProductsEdit;
import { Layout, Card, Input, Space, Button, Table, Modal, Form, message } from 'antd';
import React from 'react';
import './index.less';
import { FormInstance } from 'antd/lib/form';
import { getData, postData } from '../../utils/fetch'
import { UsecubesEANCodeIf } from '../../../../server/src/usecubes-eancode/usecubes-EANcode.interface';

const { Content } = Layout;

class UsecubesEANCodeEdit extends React.Component {

    componentDidMount = () => {
        this.getList();
    }

    state: { [key: string]: any } = {
        products: [],
        createEANcodeModalVisiable: false,
    }

    createEANcodeFormRef = React.createRef<FormInstance>();

    productColumns = [
        {
            title: '商品名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '商品编码',
            dataIndex: 'usecubesId',
            key: 'usecubesId',
        },
        {
            title: 'EAN编码',
            dataIndex: 'EANcode',
            key: 'EANcode',
        },
    ]

    onSearch = (nameOrUsecubesId: string) => {
        this.getList(new Map([
            ['keywords', nameOrUsecubesId]
        ]))
    }

    showUpdateEANcodeModal = (EANcodeInfo: UsecubesEANCodeIf) => {
        this.toggleModal()
    }

    toggleModal = () => {
        this.setState({createEANcodeModalVisiable: !!!this.state.createEANcodeModalVisiable }, () => {
            this.createEANcodeFormRef.current?.resetFields();
        })
    }

    getList = async (params?: Map<string, string>) => {
        let url = '/api/v1/usecubes_eancode/list';
        const products = await getData(url, params)
        this.setState({
            products: products.list
        })
    }

    createEANcode = async () => {
        const createEANcodeForm = this.createEANcodeFormRef.current;
        if (createEANcodeForm) {
            createEANcodeForm.validateFields().then(async value => {
                try {
                    let postResData = await postData('/api/v1/usecubes_eancode', value)
                    if (postResData.status > 200) {
                        message.error(postResData.message)
                    } else {
                        this.setState({
                            createEANcodeModalVisiable: false,
                        }, () => {
                            this.createEANcodeFormRef.current?.resetFields()
                            this.getList();
                        })
                    }
                } catch {
                    //请求错误
                }
            });
        }
    }

    render() {
        return (
            <Content>
                <Card className="jd-products-content">
                    <Space size="large" direction="vertical" style={{ width: "100%" }}>
                        <Space>
                            {/* <Search size='large' placeholder="查询商品id、名称、条码" allowClear onSearch={this.onSearch} style={{ width: '500px' }} enterButton /> */}
                            <Button type='primary' size='large' onClick={this.toggleModal.bind(this, undefined)}>新增EAN编码</Button>
                        </Space>
                        <Table rowKey="id" dataSource={this.state.products} columns={this.productColumns} pagination={false} />
                    </Space>
                </Card>
                <Modal title="新增EAN编码" visible={this.state.createEANcodeModalVisiable} onOk={this.createEANcode} cancelText="取消" okText="提交" onCancel={this.toggleModal.bind(this, undefined)}>
                    <Form name="createEANcodeForm" ref={this.createEANcodeFormRef} size="large">
                        <Form.Item name={'name'} label="商品名称" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={'usecubesId'} label="商品编码" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Form>
                </Modal>
            </Content>
        );
    }
}

export default UsecubesEANCodeEdit;
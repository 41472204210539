import { Layout, Card, Input, Space, Button, Table, Modal, Form, message, Select } from 'antd';
import React from 'react';
import './index.less';
import { FormInstance } from 'antd/lib/form';
import { getData, postData, updateData } from '../../utils/fetch'
import { UsecubesBarcodeIf } from '../../../../server/src/usecubes-barcode/usecubes-barcode.interface';

const { Content } = Layout;
const { Option } = Select;

class UsecubesBarcodeEdit extends React.Component {

    componentDidMount = () => {
        this.getList();
    }

    state: { [key: string]: any } = {
        products: [],
        createBarcodeModalVisiable: false,
        updatingProduct: {}
    }

    createBarcodeFormRef = React.createRef<FormInstance>();

    productColumns = [
        {
            title: '商品名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '商品编码',
            dataIndex: 'usecubesId',
            key: 'usecubesId',
        },
        {
            title: '状态',
            dataIndex: 'productStatus',
            key: 'productStatus',
        },
        {
            title: '像素屋条码',
            dataIndex: 'barcode',
            key: 'barcode',
        }, 
        {
            title: '条码状态',
            dataIndex: 'status',
            key: 'status',
        },{
            title: '操作',
            key: 'action',
            render: (product: UsecubesBarcodeIf) => <Button type="primary" size="small" onClick={this.showUpdateBarcodeModal.bind(this, product)}>更新</Button>
        },
    ]

    onSearch = (nameOrUsecubesId: string) => {
        this.getList(new Map([
            ['keywords', nameOrUsecubesId]
        ]))
    }

    showUpdateBarcodeModal = (barcodeInfo: UsecubesBarcodeIf) => {
        this.toggleModal(barcodeInfo)
    }

    toggleModal = (barcodeInfo?: UsecubesBarcodeIf) => {
        this.setState({ updatingProduct: barcodeInfo, createBarcodeModalVisiable: !!!this.state.createBarcodeModalVisiable }, () => {
            if (this.state.createBarcodeModalVisiable) {
                if (barcodeInfo) {
                    this.createBarcodeFormRef.current?.setFieldsValue({
                        name: barcodeInfo.name,
                        barcode: barcodeInfo.barcode,
                        type: barcodeInfo.barcode ? barcodeInfo.barcode.substring(0, 2) : '',
                        usecubesId: barcodeInfo.usecubesId,
                        productStatus: barcodeInfo.productStatus,
                        status: barcodeInfo.status,
                    })
                }
            }else{
                this.createBarcodeFormRef.current?.resetFields();
            }

        })
    }

    getList = async (params?: Map<string, string>) => {
        let url = '/api/v1/usecubes_barcode/list';
        const products = await getData(url, params)
        this.setState({
            products: products.list
        })
    }

    createBarcode = async () => {
        const createBarcodeForm = this.createBarcodeFormRef.current;
        if (createBarcodeForm) {
            createBarcodeForm.validateFields().then(async value => {
                try {
                    let postResData;
                    if (this.state.updatingProduct && this.state.updatingProduct.id) {
                        value.id = this.state.updatingProduct.id;
                        postResData = await updateData('/api/v1/usecubes_barcode', value)
                    } else {
                        postResData = await postData('/api/v1/usecubes_barcode', value)
                    }
                    if (postResData.status > 200) {
                        message.error(postResData.message)
                    } else {
                        this.setState({
                            createBarcodeModalVisiable: false,
                            updatingProduct: {}
                        }, () => {
                            this.createBarcodeFormRef.current?.resetFields()
                            this.getList();
                        })
                    }

                } catch {
                    //请求错误
                }
            });
        }
    }

    render() {
        return (
            <Content>
                <Card className="jd-products-content">
                    <Space size="large" direction="vertical" style={{ width: "100%" }}>
                        <Space>
                            {/* <Search size='large' placeholder="查询商品id、名称、条码" allowClear onSearch={this.onSearch} style={{ width: '500px' }} enterButton /> */}
                            <Button type='primary' size='large' onClick={this.toggleModal.bind(this, undefined)}>新增条码</Button>
                        </Space>
                        <Table rowKey="id" dataSource={this.state.products} columns={this.productColumns} pagination={false} />
                    </Space>
                </Card>
                <Modal title="新增条码" visible={this.state.createBarcodeModalVisiable} onOk={this.createBarcode} cancelText="取消" okText="提交" onCancel={this.toggleModal.bind(this, undefined)}>
                    <Form name="createBarcodeForm" ref={this.createBarcodeFormRef} size="large">
                        <Form.Item name={'name'} label="商品名称" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={'usecubesId'} label="商品编码" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={'productStatus'} label="商品状态">
                            <Input placeholder='仅亚马逊商品填此信息' />
                        </Form.Item>
                        <Form.Item name={'barcode'} label="商品条码">
                            <Input placeholder='若已存在条码，在此录入' />
                        </Form.Item>
                        {/* 若想要增加类型，就用英文前两个字母 */}
                        <Form.Item name={'type'} label="产品类型" rules={[{ required: true }]}>
                            <Select style={{ width: 120 }}>
                                <Option value="BL">积木 Blocks</Option>
                                <Option value="CA">卡牌 Cards</Option>
                                <Option value="AB">立牌 Acrylic brand</Option>
                                <Option value="BA">徽章 Badge</Option>
                                <Option value="GK">手办 GK</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name={'status'} label="状态" rules={[{ required: true }]}>
                            <Select style={{ width: 120 }}>
                                <Option value={0}>发货时无需打印</Option>
                                <Option value={1}>发货时需要打印</Option>
                                <Option value={2}>作废此条码</Option>
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
            </Content>
        );
    }
}

export default UsecubesBarcodeEdit;
const fetchConfig = {
    headers: new Headers({
        'Content-Type': 'application/json'
    })
}

export const postData = async (url: string, body: any): Promise<any> => {
    const fetchOption = Object.assign({}, fetchConfig, {
        method: "POST",
        body: JSON.stringify(body)
    })
    return new Promise((resolve, reject) => {
        fetch(url, fetchOption).then(res => {
            res.json()
                .then(resObject => {
                    if(resObject.statusCode === 401){
                        //redirect to login
                        window.location.href = "/#/login"
                    }else{
                        resolve(resObject)
                    }
                }).catch(reject)
        }).catch(reject)
    })
}

export const updateData = async (url: string, body: Map<string, any>): Promise<any> => {
    const fetchOption = Object.assign({}, fetchConfig, {
        method: "PUT",
        body: JSON.stringify(body)
    })
    return new Promise((resolve, reject) => {
        fetch(url, fetchOption).then(res => {
            res.json()
                .then(resObject => {
                    if(resObject.statusCode === 401){
                        //redirect to login
                        window.location.href = "/#/login"
                    }else{
                        resolve(resObject)
                    }
                }).catch(reject)
        }).catch(reject)
    })
}

export const getData = async (url: string, params?: Map<string, string>): Promise<any> => {
    const fetchOption = Object.assign({}, fetchConfig, {
        method: "GET",
        mode: 'cors' as RequestMode,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET'
        }
    })
    const record: Record<string, string> = {}
    if (params) {
        params.forEach((value, key) => {
            record[key] = value as string
        })
    }
    const getUrl = (url + "?" + new URLSearchParams(record).toString());
    return new Promise((resolve, reject) => {
        fetch(getUrl, fetchOption).then(res => {
            res.json()
                .then(resObject => {
                    if(resObject.statusCode === 401){
                        //redirect to login
                        window.location.href = "/#/login"
                    }else{
                        resolve(resObject)
                    }
                }).catch(reject)
        }).catch(reject)
    })
}